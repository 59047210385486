import * as React from 'react'
import appcuesIconUrl from 'assets/images/appcues-launcher.png'
import 'components/AppcuesQuickLaunchMenu/AppcuesQuickLaunchMenu.scss'

export const AppCuesLaunchMenu = () => {
  React.useLayoutEffect(() => {
    window.Appcues?.loadLaunchpad('#appcues-launch-button', {
      position: 'top-right',
      header: '<h3>Resource Center</h3>',
      icon: appcuesIconUrl,
    })
  })
  return (
    <div
      className="d-flex align-items-center justify-content-center mb-2"
      id="appcues-launch-button"
    />
  )
}
