import {
  MainstayFlexTableCol,
  MainstayFlexTableHeaderCol,
  IMainstayFlexTableColProps,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import {
  MainstayFlexTable,
  IMainstayFlexTable,
} from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import * as React from 'react'
import classnames from 'classnames'
import pluralize from 'pluralize'
import { withRouter, RouteComponentProps, useHistory } from 'react-router'
import {
  appendQueryFilter,
  getQueryFilters,
  hasQueryFilter,
  removeQueryFilter,
} from 'util/queryFilters'
import 'components/ContactPanel/ContactPanel.scss'
import { RDK } from 'store/webdata'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'
import { CenteredLoader } from 'components/Loader/Loader'
import { Pager } from 'components/LinkPager/LinkPager'
import { cancelable, CancelablePromise } from 'cancelable-promise'
import { usePrevious } from 'util/hooks'
import uuid from 'uuid/v4'
import SortArrow from 'components/Icons/SortArrow/SortArrow'
import 'components/Audiences/Audiences.scss'
import { EventAction } from 'components/EventTracker/EventTracker'

export interface ITableAsyncColumnProps extends IMainstayFlexTableColProps {
  title: string
  // This prop is used to add a pseudo-column (column with no header)
  // to the left of the start of the column header row.
  // Used in the <SortableContactsTable /> component.
  // There, pass in a <TransportIcon /> as part of the First Name column,
  // which pushes the row content to the right.
  // Thus the need for adding space w/ the same width to the start of the header.
  rowPrefix?: JSX.Element
  sortByField?: string
  alignment?: 'start' | 'center' | 'end'
  titleClassName?: string
  eventTrackerName?: string
}

export interface IAsyncRows {
  rows: (JSX.Element | string)[][]
  total: number
  error?: boolean
}

export interface IAsyncTableOptions {
  page?: number
  sortBy?: string
  order?: 'asc' | 'desc'
}

interface ISortTableAsyncProps extends IMainstayFlexTable {
  columns: ITableAsyncColumnProps[]
  showLoader?: boolean
  extraLoaderArgs?: { [k: string]: boolean | string | number | Date }
  tablePrefix?: string // Used to distinguish query params if multiple Async Tables on same page
  pagerHash?: string
  itemsName?: string // Used for showing total count text
  initialTableOptions?: IAsyncTableOptions
  pageSize?: number
  loadRows?: (
    tableOptions: IAsyncTableOptions,
    pageSize: number,
    extraLoaderArgs?: { [k: string]: boolean | string | number | Date }
  ) => IAsyncRows | Promise<IAsyncRows>
  fetchRows?: (
    tableOptions: IAsyncTableOptions,
    pageSize: number,
    extraLoaderArgs?: { [k: string]: boolean | string | number | Date }
  ) => IAsyncRows | Promise<void>
  renderRows?: IAsyncRows
  rowClassName?: string
  pagerClassName?: string
  eventAction?: EventAction
  eventLocation?: string
  pagerEventObject?: string
  rowEventObject?: string
}

export const useResetPageNumberOnSearch = (
  query: string,
  pageParam?: string
) => {
  // refresh page number if search query changes
  const history = useHistory()
  const previousQuery = usePrevious(query) || ''
  React.useEffect(() => {
    if (query !== previousQuery) {
      history.replace(
        appendQueryFilter(window.location, pageParam ?? 'page', '1')
      )
    }
  }, [history, query, pageParam, previousQuery])
}

const getTableOptionsFromQueryParams = (
  tablePrefix: string,
  initialTableOptions?: IAsyncTableOptions
): IAsyncTableOptions => {
  const queryParams = getQueryFilters(window.location)
  return {
    ...initialTableOptions,
    ...Object.keys(queryParams).reduce((acc: IAsyncTableOptions, key) => {
      const groups = key.match(`(^${tablePrefix})(page|sortBy|order)$`)
      if (groups?.length === 3) {
        switch (groups[2]) {
          case 'page':
            return {
              ...acc,
              [groups[2]]: Number(queryParams[key]),
            }
          case 'sortBy':
          case 'order':
          default:
            return {
              ...acc,
              [groups[2]]: queryParams[key],
            }
        }
      }
      return acc
    }, {}),
  }
}

export const SortTableAsync = withRouter(
  ({
    columns,
    loadRows,
    fetchRows,
    renderRows,
    itemsName,
    pageSize = 20,
    initialTableOptions,
    tablePrefix = '',
    pagerHash = '',
    location,
    history,
    match,
    staticContext,
    showLoader,
    extraLoaderArgs,
    rowClassName,
    pagerClassName,
    eventAction,
    eventLocation,
    rowEventObject,
    pagerEventObject,
    ...flexTableProps
  }: ISortTableAsyncProps & RouteComponentProps<void>) => {
    const [rowData, setRowData] = React.useState<IAsyncRows>()
    const [status, setStatus] = React.useState<RDK>(RDK.Loading)
    const [promise, setPromise] = React.useState<
      CancelablePromise<IAsyncRows | void>
    >()
    const previousPromise = usePrevious(promise)

    const tableOptions = getTableOptionsFromQueryParams(
      tablePrefix,
      initialTableOptions
    )

    React.useEffect(() => {
      if (!hasQueryFilter(window.location, 'sortBy')) {
        return
      }
      if (initialTableOptions?.sortBy) {
        history.replace(
          appendQueryFilter(
            window.location,
            `${tablePrefix}sortBy`,
            initialTableOptions?.sortBy
          )
        )
      }
      if (initialTableOptions?.order) {
        history.replace(
          appendQueryFilter(
            window.location,
            `${tablePrefix}order`,
            initialTableOptions?.order
          )
        )
      }
    }, [
      history,
      tablePrefix,
      initialTableOptions?.order,
      initialTableOptions?.sortBy,
    ])

    React.useEffect(() => {
      if (rowData?.error) {
        setStatus(RDK.Failure)
      }
    }, [rowData?.error])

    React.useEffect(() => {
      // If the promise has changed, cancel the old one
      if (previousPromise && previousPromise !== promise) {
        previousPromise.cancel()
      }
    }, [previousPromise, promise])

    React.useEffect(() => {
      if (loadRows) {
        setStatus(RDK.Loading)
        const newRows = loadRows(
          {
            page: tableOptions?.page || 1,
            sortBy: tableOptions?.sortBy,
            order: tableOptions?.order,
          },
          pageSize,
          extraLoaderArgs
        )

        if (newRows instanceof Promise) {
          setPromise(
            cancelable(newRows).then(a => {
              setRowData(a)
              setStatus(RDK.Success)
            })
          )
        } else {
          setRowData(newRows)
          setStatus(RDK.Success)
        }
      }
    }, [
      loadRows,
      pageSize,
      tableOptions.page,
      tableOptions.sortBy,
      tableOptions.order,
      extraLoaderArgs,
    ])

    React.useEffect(() => {
      if (fetchRows) {
        setStatus(RDK.Loading)
        const newRows = fetchRows(
          {
            page: tableOptions?.page || 1,
            sortBy: tableOptions?.sortBy,
            order: tableOptions?.order,
          },
          pageSize,
          extraLoaderArgs
        )
        if (newRows instanceof Promise) {
          setPromise(
            cancelable(newRows).then(() => {
              setStatus(RDK.Success)
            })
          )
        } else {
          setStatus(RDK.Success)
        }
      }
    }, [
      fetchRows,
      pageSize,
      tableOptions.page,
      tableOptions.sortBy,
      tableOptions.order,
      extraLoaderArgs,
    ])

    React.useEffect(() => {
      if (renderRows) {
        setRowData(renderRows)
      }
    }, [renderRows])

    return (
      <MainstayFlexTable className="border-none" {...flexTableProps}>
        <MainstayFlexTableHeader className="border-2px align-items-end">
          {columns.map(
            ({
              title,
              sortByField,
              alignment,
              titleClassName,
              eventTrackerName,
              rowPrefix,
              ...flexColProps
            }) => {
              const isSelectedColumn =
                tableOptions.sortBy && sortByField === tableOptions.sortBy
              return (
                <MainstayFlexTableHeaderCol
                  key={title}
                  className={`d-flex justify-content-${alignment} text-align-${alignment}`}
                  eventAction={eventAction}
                  eventLocation={eventLocation}
                  eventObject={
                    eventTrackerName ? `${eventTrackerName} sort` : undefined
                  }
                  {...flexColProps}>
                  {rowPrefix || null}
                  <div
                    className={classnames(
                      'd-flex flex-col align-items-center',
                      { pointer: !!sortByField }
                    )}
                    onClick={
                      sortByField
                        ? () => {
                            const newSortOrder =
                              sortByField === tableOptions.sortBy &&
                              tableOptions.order === 'desc'
                                ? 'asc'
                                : 'desc'
                            const newSortBy = sortByField
                            history.replace(
                              appendQueryFilter(
                                window.location,
                                `${tablePrefix}sortBy`,
                                newSortBy
                              )
                            )
                            history.replace(
                              appendQueryFilter(
                                window.location,
                                `${tablePrefix}order`,
                                newSortOrder
                              )
                            )
                            history.replace(
                              removeQueryFilter(
                                window.location,
                                `${tablePrefix}page`,
                                undefined,
                                pagerHash
                              )
                            )
                          }
                        : undefined
                    }>
                    <div className="d-flex align-items-end">
                      <div>
                        {isSelectedColumn && (
                          <SortArrow
                            className="fill-mainstay-dark-blue-80"
                            direction={tableOptions.order}
                          />
                        )}
                      </div>
                      <div
                        className={classnames(titleClassName, 'fs-14 caption', {
                          'font-weight-bold': !!isSelectedColumn,
                        })}>
                        {title}
                      </div>
                    </div>
                  </div>
                </MainstayFlexTableHeaderCol>
              )
            }
          )}
        </MainstayFlexTableHeader>
        <RefetchingOverlay enabled={status === RDK.Loading || !!showLoader}>
          {status === RDK.Failure ? (
            <MainstayFlexTableRow className="p-2 justify-content-center">
              Failed to retrieve {itemsName || 'rows'}.
            </MainstayFlexTableRow>
          ) : rowData ? (
            <>
              {rowData.rows.map(row => (
                <MainstayFlexTableRow
                  key={uuid()}
                  className={rowClassName}
                  eventAction={eventAction}
                  eventLocation={eventLocation}
                  eventObject={rowEventObject}>
                  {row.map((col, i) =>
                    typeof col === 'string' ? (
                      <MainstayFlexTableCol
                        key={uuid()}
                        className={`d-flex justify-content-${columns[i].alignment} text-align-${columns[i].alignment}`}
                        xs={columns[i].xs}
                        {...columns[i]}>
                        {col}
                      </MainstayFlexTableCol>
                    ) : (
                      col
                    )
                  )}
                </MainstayFlexTableRow>
              ))}
              {itemsName && (
                <div className="d-flex flex-row justify-content-end w-100 mt-2">
                  {pluralize(itemsName, rowData.total, true)}
                </div>
              )}

              {rowData.total > pageSize && (
                <Pager
                  className={pagerClassName}
                  urlFormat={x =>
                    appendQueryFilter(
                      window.location,
                      `${tablePrefix}page`,
                      x,
                      false,
                      pagerHash ?? ''
                    )
                  }
                  current={tableOptions.page || 1}
                  last={Math.ceil(rowData.total / pageSize)}
                  eventAction={eventAction}
                  eventLocation={eventLocation}
                  eventObject={pagerEventObject}
                />
              )}
            </>
          ) : (
            <CenteredLoader className="p-4" />
          )}
        </RefetchingOverlay>
      </MainstayFlexTable>
    )
  }
)
