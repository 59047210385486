import React from 'react'
import { IAutoSuggestOption } from 'components/AutoSuggest/AutoSuggest'
import { IDialogForUnknownContact } from 'api/response'
import * as api from 'api'
import AsyncAutoSuggest from 'components/AutoSuggest/AsyncAutoSuggest'
import { ValueType } from 'react-select/lib/types'
import { OptionProps } from 'react-select/lib/components/Option'
import { MenuListProps } from 'react-select/lib/components/Menu'
import { Link } from 'util/routing'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

import { components } from 'react-select'
import { ValueContainerProps } from 'react-select/lib/components/containers'
import 'components/AsyncCustomScriptSearch/AsyncCustomScriptSearch.scss'
import { readOnlyStyle } from 'components/Select/SelectV2'
import scssVariables from 'scss/_variables.scss'

interface IMenuListProps extends MenuListProps {
  getStyles: (name: string, props: MenuListProps) => {}
}

export const CustomMenuList = (props: IMenuListProps) => {
  return (
    <div ref={props.innerRef} style={props.getStyles('menuList', props)}>
      {props.children}
    </div>
  )
}

interface ICustomOptionWithValueProps<T extends unknown>
  extends OptionProps<T> {
  getLink: (id: string) => string
  omitValue?: boolean
}

export const CustomOptionWithValue = <T extends unknown>(
  props: ICustomOptionWithValueProps<T>
) => {
  return (
    <div
      className="d-flex flex-row w-100 justify-content-between align-items-center hover-bg-mainstay-dark-blue-10"
      ref={props.innerRef}
      {...props.innerProps}
      style={{
        ...props.getStyles('option', props),
        backgroundColor:
          props.isFocused || props.isSelected
            ? scssVariables.mainstayDarkBlue20
            : 'white',
        color: scssVariables.mainstayDarkBlue,
      }}>
      <div className="d-flex w-85 flex-column">
        <div className="text-ellipsis">{props.children}</div>
        {!props.omitValue && (
          <div className="text-muted font-size-xs mr-2">
            {/* tslint:disable-next-line: no-unsafe-any */}
            {props.data?.value ?? ''}
          </div>
        )}
      </div>
      <div>
        {/* tslint:disable-next-line: no-unsafe-any */}
        {props.data?.value && props.isFocused && (
          /* tslint:disable-next-line: no-unsafe-any */
          <Link to={props.getLink(props.data.value)} target="_blank">
            <AHIcon name="open_in_new" className="pointer" />
          </Link>
        )}
      </div>
    </div>
  )
}

export type SelectChangeEvent = React.ChangeEvent<{
  readonly value: string | string[]
  readonly name?: string
  readonly data: ValueType<IAutoSuggestOption<IDialogForUnknownContact>>
}>

interface IAsyncCustomScriptSearchProps {
  readonly onChange: (e: SelectChangeEvent) => void
  readonly name?: string
  readonly value?:
    | IAutoSuggestOption<IDialogForUnknownContact>
    | IAutoSuggestOption<IDialogForUnknownContact>[]
  readonly isValid?: boolean
  readonly touched?: boolean
  readonly className?: string
  readonly key?: string
  readonly readOnly?: boolean
}

interface IOption {
  value: string
  label: string
}

interface IValueContainerProps extends ValueContainerProps<IOption> {
  className?: string
  readOnly?: boolean
}

export const ValueContainer = ({
  className,
  readOnly,
  ...props
}: IValueContainerProps) => {
  let link = undefined
  /* tslint:disable-next-line: no-unsafe-any */
  const id = props.selectProps.value?.value
  /* tslint:disable-next-line: no-unsafe-any */
  const selectId = props.selectProps.id

  if (id) {
    if (selectId === 'script-filter-builder') {
      link = `/campaign-scripts/${id}/view`
    }
    if (selectId === 'campaign-filter-builder') {
      link = `/campaigns/${id}`
    }
  }

  if (link !== undefined) {
    return (
      <components.ValueContainer {...props} className="value-container">
        <div className="text-ellipsis">{props.children}</div>
        <div className="mt-1 ml-5">
          {!readOnly &&
            /* tslint:disable-next-line: no-unsafe-any */
            (!props.selectProps.inputValue ||
              /* tslint:disable-next-line: no-unsafe-any */
              props.selectProps.inputValue === id) &&
            /* tslint:disable-next-line: no-unsafe-any */
            !props.selectProps.isLoading && (
              <Link to={link} target="_blank">
                <AHIcon name="open_in_new" className="pointer" />
              </Link>
            )}
        </div>
      </components.ValueContainer>
    )
  }
  return <components.ValueContainer {...props} />
}

const customStyles = {
  singleValue: (provided: React.CSSProperties) => ({
    ...provided,
    width: '70% !important',
    color: `${scssVariables.mainstayDarkBlue} !important`,
  }),
}
export function AsyncCustomScriptSearch({
  onChange,
  value,
  name = 'custom-script',
  isValid = true,
  touched = false,
  className,
  key,
  readOnly = false,
}: IAsyncCustomScriptSearchProps) {
  return (
    <AsyncAutoSuggest
      styles={readOnly ? { ...customStyles, ...readOnlyStyle } : customStyles}
      key={key}
      name={name}
      formGroupClassnames={className}
      defaultOptions
      onChange={onChange}
      loadOptions={fetchCustomScripts}
      value={value}
      disabled={readOnly}
      readOnly={readOnly}
      touched={touched}
      isValid={isValid}
      closeMenuOnSelect={true}
      components={{
        Option: props => (
          <CustomOptionWithValue
            className="react-select__option"
            getLink={(id: string) => `/campaign-scripts/${id}/view`}
            {...props}
          />
        ),
        MenuList: props => (
          <CustomMenuList
            children={props.children}
            innerRef={props.innerRef}
            {...props}
          />
        ),
        ValueContainer: props => (
          <ValueContainer readOnly={readOnly} {...props} />
        ),
      }}
    />
  )
}

function fetchCustomScripts(
  inputValue: string,
  callback: (options: IAutoSuggestOption<IDialogForUnknownContact>[]) => void
) {
  api
    .listCampaignScripts(inputValue)
    .then(r => {
      callback(
        r.data.results.map(r => ({
          value: r.id,
          label: r.name,
          data: { ...r, prompt: r.firstMessage },
        }))
      )
    })
    .catch(e => {
      // tslint:disable-next-line no-console
      console.error('problem searching campaign scripts', e)
    })
}
