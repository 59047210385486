// TODO(tmoliter): Add all routes to this page and update places where we

// ad-hoc pass strings into <Route/>, <Link/> etc.
export const ROUTE_PARAMS = {
  PAGE_NUMBER: ':currentPage([0-9]*)',
  CONVERSATION_ID: ':conversationId',
  MESSAGE_ID: ':messageId',
  KNOWLEDGE_ID: ':knowledgeId',
  IMPORT_REPORT_ID: ':id',
  CATEGORY: ':category',
  PASSWORD_RESET: ':token',
  ENROLL: ':token',
}

export const DASHBOARD = {
  INDEX: '/dashboard/',
  CREATE: '/dashboard/create',
  EDIT: '/dashboard/edit/:shareId',
  ENGAGEMENT: '/dashboard/engagement',
  KNOWLEDGE_OVERVIEW: '/dashboard/knowledge-overview',
}

export const TRIAGE = {
  ROOT: '/triage/',
  INDEX: '/triage/inbox/',
  INBOX_ROUTE: `/triage/inbox/${ROUTE_PARAMS.PAGE_NUMBER}`,
  KNOWLEDGE_BUILDER: '/triage/knowledge',
  CONVERSATION_ROUTE: `/triage/conversation/${ROUTE_PARAMS.CONVERSATION_ID}?`,
  ADD_CONTACT_ROUTE: `/triage/add-contact`,
  ACTIVITY_LOG_ROUTE: '/triage/activity-log',
}

export const KNOWLEDGE_BUILDER_WIZARD = {
  INDEX: '/',
  CREATE: 'create',
  EDIT: 'edit',
  DONE: 'done',
}

export const CONVERSATION_ROUTES = {
  KNOWLEDGE: '/knowledge',
  KNOWLEDGE_BASE: '/knowledge-base',
  CONTACT_FEEDBACK: '/review-items/contact-feedback',
  FORWARD: `/forward/${ROUTE_PARAMS.MESSAGE_ID}?`,
  PROFILE: '/profile',
  DIALOG_SENDER: '/campaign-introducer',
}

export const CONVERSATIONS_V2 = {
  INDEX: '/conversations-v2',
  DETAIL: '/conversations-v2/:id/:channel',
}

export const ATTRIBUTES = {
  INDEX: '/attributes/',
}

export const SPECIAL_RESPONSES = {
  INDEX: '/special-responses/',
}

export const KNOWLEDGE_SOURCES = {
  INDEX: '/knowledge-sources/',
}

export const CONTACTS = {
  INDEX: '/contacts/',
  IMPORT_REPORT_LIST: '/contacts/import-reports/',
  IMPORT_PAGE: '/contacts/imports/new',
  IMPORT_DETAIL: '/contacts/import-reports/:id?',
  PROFILE_FIELDS: '/contacts/fields',
  CONTACT_PANEL: '/contacts/:id',
  NEW_CONTACT_PANEL: '/contacts/create/new',
  CONTACT_LABEL_MANAGER: '/contacts/label-manager/',
  AUDIENCES: '/audiences/',
  AUDIENCE_DETAIL: '/audiences/:id/:tab?',
}

export const CAMPAIGN_SCHEDULER_ROUTES = {
  INDEX: `/schedule-campaign`,
  EDIT_INDEX: `/schedule-campaigns/`,
  STUDENT_SELECTION: `/student-selection/`,
  UPLOAD_CSV: `/student-selection/upload/`,
  UPLOAD_CSV_CONFIRMATION: `/student-selection/upload/confirm/`,
  SCRIPT_SELECTION: `/script/`,
  SCRIPT_SELECTION_CONFIRMATION: `/script/confirm/`,
  TRIGGER_CONFIG: `/trigger/`,
}

export const AUTH = {
  LOGIN: '/login/',
  ENROLL: `/enroll-account/${ROUTE_PARAMS.ENROLL}`,
  PASSWORD_RESET_REQUEST: '/password-reset',
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: `/password-reset/${ROUTE_PARAMS.PASSWORD_RESET}`,
  ACCOUNT: '/account/',
  SSO_LOGIN_VERIFY: '/login/sso/verify',
}

export const INTERNAL = {
  DATA_PARTY: '/api/data/',
  SCRIPT_LIBRARY_ADMIN: '/internal/script-library',
  MAP_ATTRIBUTES: '/internal/map-attributes',
  DELETE_BOT: '/internal/delete-bot',
  AUDIT_ACCESS: '/internal/audit-access',
  GENERATIVE_AI_TRANSACTIONS: '/internal/generative-ai/transactions',
  KNOWLEDGE_BASE_SCRAPING: '/internal/generative-ai/knowledge-base-scraping/',
  UPLOAD_KNOWLEDGE_BASE: '/internal/upload-kb',
}

export const SETTINGS = {
  NEW_SETTINGS: '/settings',
  SETTINGS: '/bot',
}

export const CALENDAR = {
  INDEX: '/calendar/',
}

export const CAMPAIGN_HISTORY = {
  ROOT: '/campaigns/',
  DETAIL: '/campaigns/:id',
  RECURRING_DETAIL: '/recurring-campaigns/:id',
  DATA_TRIGGERED_DETAIL: '/data-triggered-campaigns/:id',
  EDIT_DATA_TRIGGERED_DETAIL: '/edit-data-triggered-campaigns/:id',
  EDIT_DETAIL: `/schedule-campaigns/:id`,
}

export const INTRO_DIALOGS = {
  WEB_BOT: '/intro-scripts/web-bot',
  WEB_HOOK: '/intro-scripts/web-hook',
  UNKNOWN_STUDENT: `/intro-scripts/unknown-contact`,
  IM_SICK: `/intro-scripts/im-sick`,
}

export const SIDEBAR_ROUTES = {
  INDEX: '/sidebar/',
}

/**************************************************************************
 * tmoliter 4/26/22:                                                      *
 * A list of all base routes that are valid to redirect to upon log-on.   *
 * We might be able to derive this once all routes are added as constants *
 * to this module. In the meantime, if a new base route is added, it must *
 * be added here to be a valid last page redirect.                        *
 **************************************************************************/
export const VALID_LAST_PAGE_REDIRECTS = new Set([
  'dashboard',
  'conversations-v2',
  'knowledge',
  'commands',
  'special-responses',
  'attributes',
  'review-items',
  'calendar',
  'campaign-scripts',
  'campaign-script-library',
  'campaigns',
  'audiences',
  'contacts',
  'settings',
  'users-v2',
])

export const DEFAULT_ROUTE = DASHBOARD.ENGAGEMENT
