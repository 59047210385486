import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import { DropdownIndicator } from 'components/SettingsEscalation/reactSelectUtils'
import ReactSelect from 'react-select'
import { Props } from 'react-select/lib/Select'
import { Styles } from 'react-select/lib/styles'
import scssVariables from 'scss/_variables.scss'
import { Control } from 'components/AutoSuggest/AutoSuggest'
import cls from 'classnames'
import React from 'react'

interface ISelectProps<T> extends Props<T> {
  error?: boolean
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
  readOnly?: boolean
  includeSearchIcon?: boolean
  explicitPreventOverlap?: boolean
}

export const readOnlyStyle: Partial<Styles> = {
  control: provided => ({
    ...provided,
    background: `${scssVariables.mainstayDarkBlue05} !important`,
    border: '0 !important',
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important',
    },
    color: `${scssVariables.mainstayDarkBlue80} !important`,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none !important',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none !important',
  }),
  option: provided => ({
    ...provided,
    color: `${scssVariables.mainstayDarkBlue80} !important`,
  }),
  singleValue: provided => ({
    ...provided,
    color: `${scssVariables.mainstayDarkBlue80} !important`,
  }),
}

// Prevent dropdowns from landing behind other page content.
// Mysteriously not always necessary to specify.
// Minimize impact by only applying based on explicitPreventOverlap prop.
const baseStyle: Partial<Styles> = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
}

export default function Select<OptionType>(props: ISelectProps<OptionType>) {
  // TOOD (Manan) - Once working, add this to storybook and reusable components stuff.
  const errorStyle: Partial<Styles> = {
    control: provided => ({
      ...provided,
      background: `${scssVariables.newUIDangerLight} !important`,
      border: 'none',
      borderWidth: 0,
    }),
    singleValue: base => ({
      ...base,
      color: `${scssVariables.newUIDanger} !important`,
    }),
    indicatorsContainer: base => ({
      ...base,
      color: `${scssVariables.newUIDanger} !important`,
      fill: `${scssVariables.newUIDanger} !important`,
    }),
  }
  const showErrorStyle = props.error && !props.isDisabled
  const showReadOnlyStyle = props.readOnly

  const styles = {
    ...(!!props.explicitPreventOverlap ? baseStyle : {}),
    ...(showErrorStyle
      ? errorStyle
      : showReadOnlyStyle
      ? readOnlyStyle
      : props.styles),
    multiValueLabel: (base: React.CSSProperties) => ({
      ...base,
      background: 'transparent !important',
      borderColor: scssVariables.mainstayBlue80,
      color: scssVariables.mainstayDarkBlue,
      padding: '0px 4px',
      fontSize: '0.8rem !important',
    }),
    clearIndicator: (base: React.CSSProperties) => ({
      ...base,
      color: scssVariables.mainstayDarkBlue,
      '&:hover': {
        color: `${scssVariables.newUIDanger} !important`,
      },
      cursor: 'pointer',
    }),
  }
  const {
    eventLocation,
    eventAction,
    eventObject,
    className,
    ...selectProps
  } = props
  return (
    <EventTrackerAttrAdder
      eventAction={eventAction}
      eventLocation={eventLocation}
      eventObject={eventObject}>
      <div className={className}>
        <ReactSelect<OptionType>
          {...selectProps}
          // Prevent dropdowns from landing behind other page content.
          // Mysteriously not always necessary to specify.
          // Minimize impact by only applying based on explicitPreventOverlap prop.
          menuPortalTarget={props.explicitPreventOverlap ? document.body : null}
          className={cls({ 'react-select--error': props.error })}
          classNamePrefix="react-select"
          styles={styles}
          hideSelectedOptions={props.hideSelectedOptions}
          components={{
            ...(props.includeSearchIcon && { Control }),
            DropdownIndicator,
            ...props.components,
          }}
        />
      </div>
    </EventTrackerAttrAdder>
  )
}
