import { Link } from 'util/routing'
import { SETTINGS } from 'const/routes'
import 'components/SettingsMenu/SettingsMenu.scss'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'

const SettingsMenu = () => {
  return (
    <>
      <h5 className="text-mainstay-almost-black-30 fs-16px">Settings</h5>
      <ul className="list-unstyled">
        <PermissionsGuard permission={PERMISSIONS.SETTINGS.VIEW}>
          <li>
            <Link
              to={SETTINGS.NEW_SETTINGS}
              className="pt-2 pb-2 fs-16px settings-menu-link">
              General Settings
            </Link>
          </li>
        </PermissionsGuard>
        <li>
          <Link to="/users-v2">Users</Link>
        </li>
        <PermissionsGuard permission={PERMISSIONS.SETTINGS.VIEW}>
          <li>
            <Link
              to="/settings/sms"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              SMS / Text Messaging
            </Link>
          </li>
          <li>
            <Link
              to="/settings/conversations"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              Conversations
            </Link>
          </li>
          <li>
            <Link
              to="/settings/generativeAI"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              Generative AI
            </Link>
          </li>
          <li>
            <Link
              to="/settings/escalation"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              Forwarding & Escalations
            </Link>
          </li>
          <li>
            <Link
              to="/settings/webchat"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              Webchat
            </Link>
          </li>
          <li>
            <Link
              to="/settings/api-tokens"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              Mainstay API
            </Link>
          </li>
        </PermissionsGuard>
        <li>
          <Link
            to="/settings/talk-to-bot"
            className="pt-2 pb-2 fs-16px settings-menu-link">
            Test the Bot
          </Link>
        </li>
        <hr className="border-color-mainstay-almost-black" />
        <h5 className="text-mainstay-almost-black-30 fs-16px">
          Third Party Integrations
        </h5>
        <PermissionsGuard permission={PERMISSIONS.INTEGRATION.VIEW}>
          <li>
            <Link
              to="/settings/browse-integrations"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              Browse Available
            </Link>
          </li>
          <li>
            <Link
              to="/settings/sync-log"
              className="pt-2 pb-2 fs-16px settings-menu-link">
              Sync Log
            </Link>
          </li>
        </PermissionsGuard>
      </ul>
    </>
  )
}

export default SettingsMenu
