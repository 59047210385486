import classNames from 'classnames'
import { Button, IButtonProps } from 'components/Button/Button'
import 'components/ContactList/ContactList.scss'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { Placement } from 'popper.js'
import * as React from 'react'
import { ButtonGroup } from 'reactstrap'
import PermissionsGuard from 'util/permissions/PermissionsGuard'

interface IMenuItems {
  icon: React.ReactNode | undefined
  label: string
  onSelect: () => void
  buttonProps?: IButtonProps
  eventAction?: EventAction
  eventLocation?: string
  eventObject?: string
  permission?: string
}
interface IActionMenuProps {
  popoverPlacement?: Placement
  children?: React.ReactNode
  menuItems?: IMenuItems[]
  className?: string
  disabled?: boolean
  menuClassName?: string
  itemClassName?: string
  eventAction?: EventAction
  eventObject?: string
  eventLocation?: string
  trackEventOnTriggerButton?: boolean
  stopPropagation?: boolean
  preventDefault?: boolean
  triggerIcon?: JSX.Element
  removePadding?: boolean
  buttonClassName?: string
  overrideButtonGroupStyles?: boolean
}

export const ActionMenu = ({
  children,
  popoverPlacement,
  menuItems,
  className,
  disabled,
  menuClassName,
  itemClassName,
  eventAction,
  eventObject,
  eventLocation,
  trackEventOnTriggerButton = true,
  stopPropagation,
  preventDefault,
  triggerIcon,
  removePadding = false,
  buttonClassName,
  overrideButtonGroupStyles,
}: IActionMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const ref = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (ref?.current) {
      isOpen ? ref.current.focus() : ref.current.blur()
    }
  }, [isOpen])

  if (disabled) {
    return (
      <div className={classNames(className, 'px-1 pt-2')}>
        <AHIcon className="text-muted" name="more_horiz" />
      </div>
    )
  }

  return (
    <PopoverComponent
      visible={isOpen}
      popoverPlacement={popoverPlacement ?? 'bottom'}
      renderReference={() => (
        <EventTrackerAttrAdder
          eventAction={trackEventOnTriggerButton ? eventAction : undefined}
          eventLocation={trackEventOnTriggerButton ? eventLocation : undefined}
          eventObject={trackEventOnTriggerButton ? eventObject : undefined}>
          <div
            tabIndex={0}
            ref={ref}
            onClick={e => {
              if (preventDefault) {
                e.preventDefault()
              }
              e.stopPropagation()
              setIsOpen(!isOpen)
            }}
            className={classNames(
              className,
              { 'px-1 pt-2': !removePadding },
              'pointer',
              'ms-action-menu'
            )}>
            {triggerIcon || (
              <AHIcon
                eventAction="click"
                eventObject="overflow menu"
                className="text-mainstay-dark-blue-80"
                name="more_horiz"
              />
            )}
          </div>
        </EventTrackerAttrAdder>
      )}
      renderPopper={() =>
        isOpen ? (
          <EventTrackerAttrAdder
            eventAction={eventAction}
            eventLocation={eventLocation}
            eventObject={eventObject}>
            <ButtonGroup
              className={classNames('bg-white', menuClassName)}
              vertical>
              {menuItems &&
                menuItems.map(
                  ({
                    icon,
                    label,
                    onSelect,
                    buttonProps,
                    eventAction,
                    eventLocation,
                    eventObject,
                    permission,
                  }) => (
                    <PermissionsGuard
                      key={`perm-guard-${label}`}
                      permission={permission}
                      className={classNames(
                        'w-100 list-group-item p-0 border-0',
                        buttonClassName
                      )}>
                      <Button
                        key={label}
                        className={classNames(
                          {
                            'bg-white list-group-item action-btn': !overrideButtonGroupStyles,
                          },
                          buttonClassName
                        )}
                        onClick={e => {
                          onSelect()
                          setIsOpen(false)
                          if (stopPropagation) {
                            e.stopPropagation()
                          }
                        }}
                        eventAction={eventAction}
                        eventLocation={eventLocation}
                        eventObject={eventObject}
                        {...buttonProps}>
                        <span className={classNames(itemClassName)}>
                          {icon}
                          {label}
                        </span>
                      </Button>
                    </PermissionsGuard>
                  )
                )}
              {children}
            </ButtonGroup>
          </EventTrackerAttrAdder>
        ) : null
      }
      onClickOutside={() => setIsOpen(false)}
    />
  )
}
