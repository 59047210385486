import { IApiError } from 'api'
import { IContactFormData } from 'components/ContactPanel/EditableContactPanel'
import { ParsedQuery } from 'query-string'
import {
  IArchiveActionType,
  IContactCounts,
  IContactNote,
  IFullContact,
} from 'store/contacts/reducer'
import { IContactAttributeValues } from 'store/personalization/contactAttributes/selectors'
import { SWITCH_CURRENT_INSTITUTION_SUCCESS } from 'store/triage/profile/actionTypes'
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions'

export const fetchContact = createAsyncAction(
  '@@@mascot/contacts/FETCH_CONTACT_START',
  '@@@mascot/contacts/FETCH_CONTACT_END',
  '@@@mascot/contacts/FETCH_CONTACT_FAIL'
)<{ id: string }, { contact: IFullContact }, IApiError>()

export const updateContact = createAsyncAction(
  '@@@mascot/contacts/UPDATE_CONTACT_START',
  '@@@mascot/contacts/UPDATE_CONTACT_END',
  '@@@mascot/contacts/UPDATE_CONTACT_FAIL'
)<
  { id: string; data: Partial<IContactFormData> },
  { contact: IFullContact },
  IApiError
>()

export const updateAttributeValues = createAsyncAction(
  '@@@mascot/contacts/UPDATE_CONTACT_ATTR_VALUES_START',
  '@@@mascot/contacts/UPDATE_CONTACT_ATTR_VALUES_END',
  '@@@mascot/contacts/UPDATE_CONTACT_ATTR_VALUES_FAIL'
)<
  { id: string; data: Pick<IContactFormData, 'attributeValues'> },
  { attributeValues: IContactAttributeValues },
  IApiError
>()

export const createContact = createAsyncAction(
  '@@@mascot/contacts/CREATE_CONTACT_START',
  '@@@mascot/contacts/CREATE_CONTACT_END',
  '@@@mascot/contacts/CREATE_CONTACT_FAIL'
)<{ data: Partial<IContactFormData> }, { contact: IFullContact }, IApiError>()

export const clearCurrentContact = createStandardAction(
  '@@@mascot/contacts/CLEAR_CURRENT_CONTACT'
)()

export const addNoteToContact = createAsyncAction(
  '@@@mascot/contacts/ADD_NOTE_START',
  '@@@mascot/contacts/ADD_NOTE_END',
  '@@@mascot/contacts/ADD_NOTE_FAIL'
)<{ id: string; note: IContactNote }, { notes: IContactNote[] }, IApiError>()

export const editContactNote = createAsyncAction(
  '@@@mascot/contacts/EDIT_NOTE_START',
  '@@@mascot/contacts/EDIT_NOTE_END',
  '@@@mascot/contacts/EDIT_NOTE_FAIL'
)<
  { id: string; noteId: string; newText: string },
  { notes: IContactNote[] },
  IApiError
>()

export const deleteContactNote = createAsyncAction(
  '@@@mascot/contacts/DELETE_NOTE_START',
  '@@@mascot/contacts/DELETE_NOTE_END',
  '@@@mascot/contacts/DELETE_NOTE_FAIL'
)<{ id: string; noteId: string }, { notes: IContactNote[] }, IApiError>()

export const getContactCounts = createAsyncAction(
  '@@@mascot/contacts/GET_CONTACT_COUNTS_START',
  '@@@mascot/contacts/GET_CONTACT_COUNTS_END',
  '@@@mascot/contacts/GET_CONTACT_COUNTS_FAIL'
)<
  { searchQuery: string; filter: ParsedQuery },
  { data: IContactCounts; status: string },
  void
>()

export const archiveOrUnarchiveContacts = createAsyncAction(
  '@@@mascot/contacts/ARCHIVE_OR_UNARCHIVE_CONTACTS_START',
  '@@@mascot/contacts/ARCHIVE_OR_UNARCHIVE_CONTACTS_END',
  '@@@mascot/contacts/ARCHIVE_OR_UNARCHIVE_CONTACTS_FAIL'
)<
  {
    searchQuery: string
    filter: ParsedQuery
    action: IArchiveActionType
    source: string
  },
  { data: IContactCounts },
  IApiError
>()

export const updateArchivePolling = createAsyncAction(
  '@@mascot/contacts/UPDATE_ARCHIVE_POLLING_START',
  '@@mascot/contacts/UPDATE_ARCHIVE_POLLING_END',
  '@@mascot/contacts/UPDATE_ARCHIVE_POLLING_FAIL'
)<{ progress: number }, void, void>()

export type IContactsActions =
  | ActionType<typeof fetchContact>
  | ActionType<typeof updateContact>
  | ActionType<typeof updateAttributeValues>
  | ActionType<typeof createContact>
  | ActionType<typeof SWITCH_CURRENT_INSTITUTION_SUCCESS>
  | ActionType<typeof addNoteToContact>
  | ActionType<typeof editContactNote>
  | ActionType<typeof deleteContactNote>
  | ActionType<typeof getContactCounts>
  | ActionType<typeof archiveOrUnarchiveContacts>
  | ActionType<typeof updateArchivePolling>
  | ActionType<typeof clearCurrentContact>
