import { getType } from 'typesafe-actions'
import {
  acceptGenerativeAiResponse,
  clearFlaggedMessages,
  extendLiveChat,
  fetchConversationDetails,
  fetchFlaggedMessageIds,
  generateAIResponse,
  IActions,
  IAutomaticGenAIResponseForFallback,
  markMessageAsUnread,
  onConversationEscalation,
  onConversationMessage,
  resetGenerativeAiResponse,
  selectFlagForReply,
  sendConversationMessage,
  transferToBot,
  undoFlaggedMessages,
  updateConversationDetailsContact,
  updateLiveChatDraftMessage,
  updateLiveChatState,
  updateMessageFlag,
} from 'page/conversations-v2/ConversationsDetail/actions'
import {
  Conversation,
  ConversationDetailsRequestStatus,
  ConversationMessage,
  FlaggedMessage,
  GenerativeResponseRequestStatus,
  getInflightMessageIds,
  InflightMessage,
  LiveChatState,
  MultiChannelFlaggedMessages,
  ReplyToFlagState,
} from 'page/conversations-v2/ConversationsDetail/index'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import pick from 'lodash/pick'
import { orderBy } from 'lodash'

type ConversationDetailsState = {
  readonly loadMoreNewer: boolean
  readonly loadMoreOlder: boolean
  readonly liveChat: {
    state: LiveChatState
    genAIResponseForFallback?: IAutomaticGenAIResponseForFallback
    status: ConversationDetailsRequestStatus
    draftMessage: string
  }
  readonly status: ConversationDetailsRequestStatus
  readonly data: Conversation
  readonly generativeAI: {
    status: GenerativeResponseRequestStatus
    generativeResponse?: string
    transactionId?: number
    confidentInAnswer?: boolean | null
    responseAccepted?: boolean
  }
  readonly inProgressMessages: InflightMessage[]
  readonly actions: ConversationDetailsActionsState
  readonly flaggedMessages: MultiChannelFlaggedMessages & {
    readonly status: ConversationDetailsRequestStatus
  }
}

type ConversationDetailsActionsState = {
  readonly markAsUnreadStatus: ConversationDetailsRequestStatus
  readonly transferToBotStatus: ConversationDetailsRequestStatus
  readonly extendLiveChatStatus: ConversationDetailsRequestStatus
  readonly flagMessages: {
    readonly messageIds: string[]
    readonly status: ConversationDetailsRequestStatus
    readonly resolveAll: boolean
  }
  readonly sendMessageStatus: ConversationDetailsRequestStatus
  readonly replyToFlag: ReplyToFlagState | null
}

const initialConversationDetailsActionsState: ConversationDetailsActionsState = {
  markAsUnreadStatus: ConversationDetailsRequestStatus.initial,
  flagMessages: {
    messageIds: [],
    status: ConversationDetailsRequestStatus.initial,
    resolveAll: false,
  },
  transferToBotStatus: ConversationDetailsRequestStatus.initial,
  sendMessageStatus: ConversationDetailsRequestStatus.initial,
  extendLiveChatStatus: ConversationDetailsRequestStatus.initial,
  replyToFlag: null,
}

const initialConversationDetailsState: ConversationDetailsState = {
  liveChat: {
    state: { kind: 'liveChatOff', channel: null },
    status: ConversationDetailsRequestStatus.initial,
    draftMessage: '',
  },
  actions: initialConversationDetailsActionsState,
  status: ConversationDetailsRequestStatus.initial,
  inProgressMessages: [],
  loadMoreNewer: false,
  loadMoreOlder: false,
  generativeAI: {
    status: GenerativeResponseRequestStatus.initial,
  },
  data: {
    contact: null,
    messages: [],
    escalations: [],
    hasMoreOlder: false,
    hasMoreNewer: false,
    resets: [],
  },
  flaggedMessages: {
    count: 0,
    messages: [],
    status: ConversationDetailsRequestStatus.initial,
    byChannel: {},
  },
}

const conversationDetails = (
  state: ConversationDetailsState = initialConversationDetailsState,
  action: IActions
): ConversationDetailsState => {
  switch (action.type) {
    case getType(fetchConversationDetails.request):
      return {
        ...state,
        loadMoreNewer: action.payload.loadMoreNewer,
        loadMoreOlder: action.payload.loadMoreOlder,
        status: ConversationDetailsRequestStatus.loading,
      }
    case getType(fetchConversationDetails.success):
      const loadedNewMsg = action.payload.contact?.id !== state.data.contact?.id

      return {
        ...state,
        status: ConversationDetailsRequestStatus.ok,
        data: {
          ...action.payload,
          ...setDataDependingOnLoadDirection(state, action.payload),
        },
        liveChat: {
          state: action.payload.contact?.chatState || {
            kind: 'liveChatOff',
            channel: null,
          },
          status: ConversationDetailsRequestStatus.initial,
          draftMessage: state.liveChat.draftMessage,
        },
        actions: loadedNewMsg
          ? initialConversationDetailsActionsState
          : state.actions,
        inProgressMessages: loadedNewMsg ? [] : state.inProgressMessages,
        loadMoreOlder: false,
        loadMoreNewer: false,
      }
    case getType(fetchConversationDetails.failure):
      return {
        ...state,
        status: action.payload,
      }
    case getType(markMessageAsUnread.request):
      if (!state.data.contact) {
        return state
      }
      return {
        ...state,
        data: {
          ...state.data,
          contact: {
            ...state.data.contact,
            lastUnreadMessageId: action.payload.messageId || null,
            conversationReadTimestamp: action.payload.timestamp,
          },
        },
        actions: {
          ...state.actions,
          markAsUnreadStatus: ConversationDetailsRequestStatus.loading,
        },
      }
    case getType(markMessageAsUnread.success):
      return {
        ...state,
        actions: {
          ...state.actions,
          markAsUnreadStatus: ConversationDetailsRequestStatus.ok,
        },
      }
    case getType(markMessageAsUnread.failure):
      return {
        ...state,
        actions: {
          ...state.actions,
          markAsUnreadStatus: ConversationDetailsRequestStatus.error,
        },
      }
    case getType(updateMessageFlag.request):
      return {
        ...state,
        actions: {
          ...state.actions,
          flagMessages: {
            ...state.actions.flagMessages,
            resolveAll: action.payload.resolveAll,
            messageIds: action.payload.messageIds,
            status: ConversationDetailsRequestStatus.loading,
          },
        },
      }
    case getType(updateMessageFlag.success):
      let newFlaggedMessages: FlaggedMessage[] = []
      const { flagged, messageIds } = action.payload

      if (flagged) {
        const messagesToFlag = state.data.messages
          .filter(msg => messageIds.includes(msg.id))
          .map(m => pick(m, ['id', 'createdAt', 'channel']))
        newFlaggedMessages = orderBy(
          [...state.flaggedMessages.messages, ...messagesToFlag],
          ['createdAt']
        )
      } else {
        newFlaggedMessages = state.flaggedMessages.messages.filter(
          msg => !messageIds.includes(msg.id)
        )
      }

      return {
        ...state,
        data: {
          ...state.data,
          messages: state.data.messages.map((item: ConversationMessage) => {
            if (!messageIds.includes(item.id)) {
              return item
            }

            return {
              ...item,
              flagged: action.payload.flagged,
            }
          }),
        },
        actions: {
          ...state.actions,
          flagMessages: {
            ...state.actions.flagMessages,
            messageIds: action.payload.messageIds,
            status: ConversationDetailsRequestStatus.ok,
          },
        },
        flaggedMessages: {
          ...state.flaggedMessages,
          count: newFlaggedMessages.length,
          messages: newFlaggedMessages,
          byChannel: separateMessagesByChannel(newFlaggedMessages),
        },
      }
    case getType(updateMessageFlag.failure):
      return {
        ...state,
        actions: {
          ...state.actions,
          flagMessages: {
            ...state.actions.flagMessages,
            messageIds: action.payload.messageIds,
            status: ConversationDetailsRequestStatus.error,
          },
        },
      }
    case getType(updateLiveChatState.request):
      return {
        ...state,
        liveChat: {
          state: {
            kind: 'liveChatStarting',
            channel: state.liveChat.state.channel,
          },
          status: ConversationDetailsRequestStatus.loading,
          draftMessage: state.liveChat.draftMessage,
        },
      }
    case getType(updateLiveChatState.success):
      return {
        ...state,
        liveChat: {
          state: action.payload.chatState,
          genAIResponseForFallback: action.payload.genAIResponseForFallback,
          status: ConversationDetailsRequestStatus.ok,
          draftMessage: state.liveChat.draftMessage,
        },
      }
    case getType(updateLiveChatState.failure):
      return {
        ...state,
        liveChat: {
          state: {
            kind: 'liveChatFailed',
            channel: state.liveChat.state.channel,
          },
          genAIResponseForFallback: {
            shouldGenerateResponse: false,
            lastestFallbackSmsLogID: null,
          },
          status: ConversationDetailsRequestStatus.error,
          draftMessage: state.liveChat.draftMessage,
        },
      }
    case getType(sendConversationMessage.request):
      return {
        ...state,
        actions: {
          ...state.actions,
          sendMessageStatus: ConversationDetailsRequestStatus.loading,
        },
        inProgressMessages: [...state.inProgressMessages, action.payload],
      }
    case getType(sendConversationMessage.success):
      return {
        ...state,
        actions: {
          ...state.actions,
          sendMessageStatus: ConversationDetailsRequestStatus.ok,
        },
      }
    case getType(sendConversationMessage.failure):
      return {
        ...state,
        actions: {
          ...state.actions,
          sendMessageStatus: ConversationDetailsRequestStatus.error,
        },
      }
    case getType(transferToBot.request):
      return {
        ...state,
        actions: {
          ...state.actions,
          transferToBotStatus: ConversationDetailsRequestStatus.loading,
        },
      }
    case getType(transferToBot.success):
      return {
        ...state,
        actions: {
          ...state.actions,
          transferToBotStatus: ConversationDetailsRequestStatus.ok,
        },
      }
    case getType(transferToBot.failure):
      return {
        ...state,
        actions: {
          ...state.actions,
          transferToBotStatus: ConversationDetailsRequestStatus.error,
        },
      }
    case getType(extendLiveChat.request):
      return {
        ...state,
        actions: {
          ...state.actions,
          extendLiveChatStatus: ConversationDetailsRequestStatus.loading,
        },
      }
    case getType(extendLiveChat.success):
      return {
        ...state,
        actions: {
          ...state.actions,
          extendLiveChatStatus: ConversationDetailsRequestStatus.ok,
        },
      }
    case getType(extendLiveChat.failure):
      return {
        ...state,
        actions: {
          ...state.actions,
          extendLiveChatStatus: ConversationDetailsRequestStatus.error,
        },
      }
    case getType(selectFlagForReply):
      return {
        ...state,
        actions: {
          ...state.actions,
          replyToFlag: action.payload,
        },
      }
    case getType(onConversationMessage):
      const existingMessages = state.data.messages.filter(
        x => x.id !== action.payload.id
      )
      const newMessages = sortBy(
        [...existingMessages, action.payload],
        'createdAt'
      )
      const deliveredInflightIds = getInflightMessageIds(newMessages)

      return {
        ...state,
        inProgressMessages: state.inProgressMessages.filter(
          x => !deliveredInflightIds.has(x.inflightId)
        ),
        data: {
          ...state.data,
          messages: newMessages,
        },
      }
    case getType(onConversationEscalation):
      const existingEscalations = state.data.escalations.filter(
        x => x.id !== action.payload.id
      )
      const newEscalations = sortBy(
        [...existingEscalations, action.payload],
        'createdAt'
      )
      return {
        ...state,
        data: {
          ...state.data,
          escalations: newEscalations,
        },
      }
    case getType(fetchFlaggedMessageIds.request):
      return {
        ...state,
        flaggedMessages: {
          ...state.flaggedMessages,
          status: ConversationDetailsRequestStatus.loading,
        },
      }
    case getType(fetchFlaggedMessageIds.failure):
      return {
        ...state,
        flaggedMessages: {
          ...state.flaggedMessages,
          status: ConversationDetailsRequestStatus.error,
        },
      }
    case getType(fetchFlaggedMessageIds.success):
      const { messages, count } = action.payload
      return {
        ...state,
        flaggedMessages: {
          messages,
          count,
          status: ConversationDetailsRequestStatus.ok,
          byChannel: separateMessagesByChannel(messages),
        },
      }

    case getType(undoFlaggedMessages):
      return {
        ...state,
        flaggedMessages: {
          ...state.flaggedMessages,
          count: state.flaggedMessages.messages.length,
          byChannel: separateMessagesByChannel(state.flaggedMessages.messages),
          status: ConversationDetailsRequestStatus.ok,
        },
      }

    case getType(clearFlaggedMessages):
      return {
        ...state,
        flaggedMessages: {
          ...initialConversationDetailsState.flaggedMessages,
          status: ConversationDetailsRequestStatus.ok,
        },
      }

    case getType(updateConversationDetailsContact):
      if (!state.data.contact) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          contact: {
            ...state.data.contact,
            name: action.payload.name,
          },
        },
      }

    case getType(generateAIResponse.request):
      return {
        ...state,
        generativeAI: {
          ...initialConversationDetailsState.generativeAI,
          status: GenerativeResponseRequestStatus.loading,
        },
      }
    case getType(generateAIResponse.success):
      return {
        ...state,
        generativeAI: {
          status: GenerativeResponseRequestStatus.ok,
          generativeResponse: action.payload.generative_response,
          transactionId: action.payload.transaction_id,
          confidentInAnswer: action.payload.confident_in_answer,
        },
      }
    case getType(generateAIResponse.failure):
      return {
        ...state,
        generativeAI: {
          status: GenerativeResponseRequestStatus.error,
        },
      }
    case getType(acceptGenerativeAiResponse):
      return {
        ...state,
        generativeAI: {
          ...state.generativeAI,
          status: GenerativeResponseRequestStatus.initial,
          responseAccepted: true,
        },
      }
    case getType(resetGenerativeAiResponse):
      return {
        ...state,
        generativeAI: initialConversationDetailsState.generativeAI,
      }
    case getType(updateLiveChatDraftMessage):
      return {
        ...state,
        liveChat: {
          ...state.liveChat,
          draftMessage: action.payload,
        },
      }

    default:
      return state
  }
}

function setDataDependingOnLoadDirection(
  state: ConversationDetailsState,
  payload: Conversation
): Partial<Conversation> {
  if (state.loadMoreNewer) {
    return {
      messages: uniqBy([...state.data.messages, ...payload.messages], 'id'),
      escalations: uniqBy(
        [...state.data.escalations, ...payload.escalations],
        'id'
      ),
      resets: uniqBy([...state.data.resets, ...payload.resets], 'id'),
      hasMoreNewer:
        payload.hasMoreNewer !== null
          ? payload.hasMoreNewer
          : state.data.hasMoreNewer,
    }
  }
  if (state.loadMoreOlder) {
    return {
      messages: uniqBy([...payload.messages, ...state.data.messages], 'id'),
      escalations: uniqBy(
        [...payload.escalations, ...state.data.escalations],
        'id'
      ),
      resets: uniqBy([...payload.resets, ...state.data.resets], 'id'),
      hasMoreOlder:
        payload.hasMoreOlder !== null
          ? payload.hasMoreOlder
          : state.data.hasMoreOlder,
    }
  }

  return {
    messages: payload.messages,
    escalations: payload.escalations,
    resets: payload.resets,
    hasMoreNewer: payload.hasMoreNewer,
    hasMoreOlder: payload.hasMoreOlder,
  }
}

function separateMessagesByChannel(messages: FlaggedMessage[]) {
  return messages.reduce((acc: { [k: string]: FlaggedMessage[] }, entry) => {
    const { id, createdAt, channel } = entry
    if (!!channel) {
      if (acc[channel]) {
        acc[channel].push({ id, createdAt, channel })
      } else {
        acc[channel] = [{ id, createdAt, channel }]
      }
    }

    return acc
  }, {})
}

export default conversationDetails
