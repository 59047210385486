import { ITemplateCollection } from 'api'
import { ITransitionType } from 'api/request'
import {
  ICampaignScriptPreviewResponse,
  ICampaignScriptResponse,
  ITemplateScriptPreview,
  ICampaignScriptscriptHistory,
  IRecurringCampaignHistoryDetailsResponseData,
  ICampaignHistoryDetailsResponseData,
  ICampaignTriggerDetail,
} from 'api/response'
import { IIntroDialogListItem } from 'components/IntroDialogList/IntroDialogList'
import * as _ from 'lodash'
import {
  fetchAggRecurringCampaignDetails,
  fetchCampaignDetails,
  fetchRecurringCampaignDetails,
  fetchCampaignTriggerDetails,
  ICampaignDetailsActions,
} from 'store/campaign-details/actions'
import {
  addBlankExitAction,
  clearBlankExitAction,
  createCampaignScript,
  createCampaignScriptInitialState,
  createLink,
  deleteCampaignScriptStateEdge,
  destroyCampaignScript,
  duplicateCampaignScript,
  fetchIntroDialogDetails,
  fetchIntroDialogsList,
  fetchTestContacts,
  generateDialogReport,
  generateDialogReportProgress,
  getAllCampaignScripts,
  ICampaignScriptActions,
  listTemplateScripts,
  refreshDialogUpdatedAt,
  retrieveCampaignScript,
  searchCampaignScripts,
  searchScriptTemplates,
  setShowPauseTimeForm,
  spliceCampaignScriptState,
  startLinking,
  campaignScriptDragState,
  stopLinking,
  updateCampaignScript,
  updateCampaignScriptState,
  updateScriptSearchQuery,
  updateSelectedDialogTags,
  updateSortfield,
  updateSortOrder,
  updateTemplateCollections,
  editScriptNodeAction,
} from 'store/campaign-scripts/actions'
import {
  bulkAddDialogLabels,
  bulkRemoveDialogLabels,
  deleteDialogLabel,
  IDialogLabelActions,
  updateDialogLabel,
} from 'store/dialog-labels/actions'
import { IDialogLabel } from 'store/dialog-labels/reducer'
import { MessagingStatus } from 'store/personalization/contactAttributes/selectors'
import { TransportId } from 'store/transport'
import { getType } from 'typesafe-actions'
import { notUndefined } from 'util/typeguards'

export interface ICampaignScriptPreview {
  id: string
  name: string
  humanName?: string | null
  description: string
  category?: string | null
  topic?: string | null
  published?: boolean | null
  lifeCycle?: string | null
  audience?: string | null
  timing?: string | null
  principle?: string | null
  realImpact?: string | null
  firstMessage?: string
  createdAt: Date | string
  sentToUsers?: boolean | null
  custom: boolean
  isInteractive: boolean
  labels: IDialogLabel[]
  frequencyCount?: number | null
}

export interface ICampaignScriptReminder {
  prompt: string
  after: number
}

export interface ICampaignScriptCustomLogic {
  type: 'SKIP' | 'CUSTOM_NEXT_STATE'
  id: string
}

interface ILinkBranches {
  readonly [id: string]:
    | {
        [id: string]: boolean | undefined
      }
    | undefined
}

export type IntroDialogKind =
  | 'UNKNOWN_STUDENT'
  | 'WEB_BOT'
  | 'WEB_HOOK'
  | 'IM_SICK'

export interface IIntroDialog {
  id: string
  humanName: string
  initialState: string
  messagedUsers: number
  distinctResponses: number
  kind: IntroDialogKind
  isRespondable: boolean
  expirationLength: number
  hidden: boolean
}

export interface ICampaignScript {
  description: string
  humanName: string
  id: string
  initialState: string
  published?: boolean
  messagingService: string
  topic?: string
  audience?: string
  timing?: string
  lifeCycle?: string
  principle?: string
  realImpact?: string
  name: string
  reminders?: ICampaignScriptReminder[]
  expirationLength?: number
  createdAt?: string
  createdBy?: string
  isMainstayStaff?: boolean
  updatedAt?: string
  scriptHistory?: ICampaignScriptscriptHistory[]
  custom: boolean
  draft?: boolean
  customLogic: null | ICampaignScriptCustomLogic[]
  sentToUsers?: boolean
  labels?: IDialogLabel[]
  collections?: ITemplateCollection[]
}

export type TestContact = {
  readonly id: string
  readonly name: {
    readonly first: string | null
    readonly last: string | null
    readonly full: string | null
  }
  readonly phone: string | null
  readonly email: string | null
  readonly transport: TransportId | 'unknown'
}

interface IWorkflowSteps {
  byId: {
    [key: string]: ICampaignScriptStep | undefined
  }
  linkBranches: ILinkBranches
  parentChildTuplesByChildId: {
    [currentStep: string]: ParentChildTuple | undefined
  }
  loading: boolean
  updating: boolean
  allIds: string[]
}

interface ICampaignScriptState {
  templatePreviewsById: {
    [key: string]: ITemplateScript | undefined
  }
  scriptPreviewsById: {
    [key: string]: ICampaignScriptPreview | undefined
  }
  selectedDialog?: ICampaignScript
  allIds: string[]
  allTemplateIds: string[]
  searching: boolean
  loading: boolean
  updating: boolean
  creating: boolean
  totalCount: number
  ui: {
    scriptSearchQuery: string
    scriptSortOrder: 'asc' | 'desc'
    scriptSortField: keyof ICampaignScriptPreview
    fetchingTestContacts: boolean
    testContacts: readonly TestContact[]
    linking: boolean
    linkFromId?: string
    linkTransition?: ITransitionType
    selectedDialogTags?: number[]
  }
  steps: IWorkflowSteps
  aggregateSteps: IWorkflowSteps
  introDialogs: {
    list: ReadonlyArray<IIntroDialogListItem>
    loading: boolean
    selectedIntroDialog: null | IIntroDialog
    reportDownloadProgressById: { [key: string]: undefined | number }
  }
}

export type ParentChildTuple = [
  ICampaignScriptStep,
  ICampaignScriptStep | null,
  string | null
]

export enum ExitActionType {
  Save = 'save',
  ScheduleJob = 'scheduleJob',
  SendEmail = 'sendEmail',
  updateOrCreateHubspotContact = 'updateOrCreateHubspotContact',
  sendToWebhook = 'sendToWebhook',
  SetMessagingStatus = 'setMessagingStatus',
}

export enum PromptType {
  number = 'Number',
  boolean = 'Boolean',
  open = 'Open',
  auto = 'Auto',
}

export interface IEmailExitActionArgs {
  newNumberField?: string
  minutes?: number
  to?: string
  counselorId?: number
  prefix?: string
  subject?: string
  methodName?: string
  question?: string
}

export interface IScheduleJobActionArgs {
  methodName: string
  methodParams?: {
    dialogId?: string
  }
}

export type IContactAttributeMapping = Array<{
  key: string
  value: string
}>

export interface ISaveExitActionArgs {
  field: string | undefined
  contactAttributeValues?: IContactAttributeMapping
  attributeId?: number
  value?: string | undefined | null
  unset?: boolean
}

export interface ISetMessagingStatusArgs {
  newMessagingStatus: MessagingStatus
  source: 'save-action'
}

export interface IExitAction<
  ActionType =
    | ISaveExitActionArgs
    | IEmailExitActionArgs
    | IScheduleJobActionArgs
    | ISetMessagingStatusArgs
    | {}
> {
  newlyCreated?: boolean
  reprompt?: boolean
  name: ExitActionType
  args: ActionType
}

export function isSaveExitAction(
  action: IExitAction
): action is IExitAction<ISaveExitActionArgs> {
  return action.name === ExitActionType.Save
}
export function isWebhookExitAction(
  action: IExitAction
): action is IExitAction<ISaveExitActionArgs> {
  return action.name === ExitActionType.sendToWebhook
}

export function isEmailExitAction(
  action: IExitAction
): action is IExitAction<IEmailExitActionArgs> {
  return action.name === ExitActionType.SendEmail
}

export function isScheduleJobExitAction(
  action: IExitAction
): action is IExitAction<IScheduleJobActionArgs> {
  return action.name === ExitActionType.ScheduleJob
}

export function isHubspotContactExitAction(
  action: IExitAction
): action is IExitAction<{}> {
  return action.name === ExitActionType.updateOrCreateHubspotContact
}

export function isSetMessagingStatusExitAction(
  action: IExitAction
): action is IExitAction<ISetMessagingStatusArgs> {
  return action.name === ExitActionType.SetMessagingStatus
}

export interface ICampaignScriptStep {
  id: string
  parentDialog: string
  readonly showPauseTimeForm?: boolean
  prompt: string
  personalizedPrompt?: string | null
  promptType: PromptType
  pauseTime?: number | null
  exitActions?: IExitAction[] | null
  nextStates: {
    [key: string]:
      | {
          default: string | boolean
        }
      // NOTE(chdsbd): Our production data isn't perfect and nextState is sometimes undefined
      | undefined
  }
  range?:
    | {
        min: number
        max: number
      }
    | {}
    | null
  media?: string | null
  mediaName?: string
  multipleChoices?:
    | {
        [prompt: string]: string
      }[]
    | null
  openPromptLabel?: string | null
  totalResponseCount?: number
  choiceResponseCounts?: null | IChoiceResult[]
  dialogStateSentToCount?: number
  editing?: boolean
}

export interface ICampaignScriptDragState {
  multipleChoices?:
    | {
        [prompt: string]: string
      }[]
    | null
  nextStates: {
    [key: string]:
      | {
          default: string | boolean
        }
      // NOTE(chdsbd): Our production data isn't perfect and nextState is sometimes undefined
      | undefined
  }
  dialogStateId: string
}
export interface IChoiceResult {
  label: string
  value: number
  cleaned: string | number
  promptType: 'Number' | 'Boolean'
}

export interface IWorkflowStepResult {
  count: number
  readonly id: string
  promptType: PromptType
  prompt: string
  personalizedPrompt: string | undefined | null
  readonly position?: number
  readonly totalResponseCount: number
  choices: null | IChoiceResult[]
}

interface IStepsById {
  readonly [id: string]: ICampaignScriptStep | undefined
}

type IVisitedTuples = ICampaignScriptState['steps']['parentChildTuplesByChildId']

interface IFindLinkBranchesReturn {
  readonly linkBranches: ILinkBranches
  readonly visitedTuples: IVisitedTuples
}
// In order to render the graph of workflow steps as a tree in the preview card,
// we need to iterate the graph depth first, and tag each previously visited branch
// as a `link`.
export const findLinkBranches = (
  steps: IStepsById,
  firstStepId: string
): IFindLinkBranchesReturn => {
  const firstStep = steps[firstStepId]
  if (firstStep == null) {
    return {
      linkBranches: {},
      visitedTuples: {},
    }
  }
  // This is a stack of tuples, where the first item is the currently examined node,
  // the second is the current node's parent, and the last value is name of the parent's
  // branch that links to the child.
  const stack: ParentChildTuple[] = [[firstStep, null, null]]
  // A collection of the link nodes, basically its a parent node id and a key to one of its branches
  // marking that node as a `link`
  const linkBranches: Mutable<ILinkBranches> = {}
  // Hash table of visited nodes.
  const visited: { [id: string]: boolean } = {}
  const visitedTuples: Mutable<IVisitedTuples> = {}
  while (stack.length > 0) {
    const tuple = stack.pop()

    if (!tuple) {
      break
    }

    if (!visitedTuples[tuple[0].id]) {
      visitedTuples[tuple[0].id] = tuple
    }
    const curr = tuple[0]
    const parent = tuple[1]
    const branch = tuple[2]
    // Here we want to record a link node's existence by its parent's branch key.
    // Basically if a branch has been visited previously we want to mark it's [ParentId][BranchName]
    // as a link node
    if (visited[curr.id]) {
      // These items are gauranteed to be set after the first iteration
      // since the conditional up here will only evaluate to true once at least
      // one node has been visited.
      if (parent != null && branch != null) {
        linkBranches[parent.id] = linkBranches[parent.id] || {}
        const branchDict = linkBranches[parent.id]
        if (branchDict == null) {
          break
        }
        branchDict[branch] = true
      }
      // we want to avoid adding the children to the stack here, so we continue.
      continue
    }
    // This check is a little convoluted, but this is how you can tell if a node is terminal.
    if (
      curr.nextStates &&
      !(
        curr.nextStates.default &&
        !curr.nextStates.default.default &&
        Object.keys(curr.nextStates).length === 1
      )
    ) {
      // Items must be inserted into the stack in reverse to preserve order.
      for (const key of Object.keys(curr.nextStates).reverse()) {
        const branchName = key
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const childStateId = curr.nextStates[branchName]!.default
        if (typeof childStateId !== 'string') {
          continue
        }
        const childState = steps[childStateId]
        if (!childState) {
          continue
        }
        stack.push([childState, curr, branchName])
      }
    }
    visited[curr.id] = true
  }
  return { linkBranches, visitedTuples }
}

const mapCampaignRetrieveToPreview = (
  campaignScript: ICampaignScriptResponse
) => {
  return {
    id: campaignScript.id,
    name: campaignScript.humanName,
    description: campaignScript.description,
    sentToUsers: campaignScript.sentToUsers,
    createdAt: campaignScript.createdAt && new Date(campaignScript.createdAt),
    published: campaignScript.published,
    custom: campaignScript.custom,
    isInteractive: false,
    labels: campaignScript.labels ?? [],
    firstMessage: campaignScript.firstMessage,
    topic: campaignScript.topic ?? undefined,
  }
}

const mapCampaignScriptSearchData = (
  campaignScript: ICampaignScriptPreviewResponse
): ICampaignScriptPreview => {
  return {
    id: campaignScript.id,
    name: campaignScript.name,
    humanName: campaignScript.humanName,
    description: campaignScript.description,
    category: campaignScript.category,
    topic: campaignScript.topic,
    lifeCycle: campaignScript.lifeCycle,
    audience: campaignScript.audience,
    timing: campaignScript.timing,
    principle: campaignScript.principle,
    realImpact: campaignScript.realImpact,
    firstMessage: campaignScript.firstMessage,
    sentToUsers: campaignScript.sentToUsers,
    createdAt: new Date(campaignScript.createdAt),
    published: campaignScript.published,
    custom: campaignScript.custom,
    isInteractive: campaignScript.isInteractive,
    labels: campaignScript.labels || [],
    frequencyCount: campaignScript.frequencyCount,
  }
}

interface ITemplateScript {
  readonly id: string
  readonly name: string
  readonly humanName: string
  readonly description: string
  readonly category?: string
  readonly topic?: string
  readonly lifeCycle?: string
  readonly audience?: string
  readonly timing?: string
  readonly principle?: string
  readonly realImpact?: string
  readonly firstMessage: string
  readonly createdAt: Date
  readonly custom: boolean
  readonly published?: boolean
  readonly sentToUsers?: boolean
  readonly isInteractive: boolean
  readonly labels: IDialogLabel[]
  readonly collections: ITemplateCollection[]
}

const mapTemplateScriptSearchData = (
  templateScript: ITemplateScriptPreview
): ITemplateScript => {
  return {
    id: templateScript.id,
    name: templateScript.name,
    humanName: templateScript.humanName,
    description: templateScript.description,
    category: templateScript.category,
    topic: templateScript.topic,
    lifeCycle: templateScript.lifeCycle,
    audience: templateScript.audience,
    timing: templateScript.timing,
    principle: templateScript.principle,
    realImpact: templateScript.realImpact,
    firstMessage: templateScript.firstMessage,
    sentToUsers: templateScript.sentToUsers,
    createdAt: new Date(templateScript.createdAt),
    published: templateScript.published,
    custom: templateScript.custom,
    isInteractive: templateScript.isInteractive,
    labels: templateScript.labels || [],
    collections: templateScript.collections || [],
  }
}

const mapCampaignScriptRetrieveData = (
  campaignScript: ICampaignScriptResponse
): ICampaignScript => {
  return {
    id: campaignScript.id,
    name: campaignScript.name,
    description: campaignScript.description,
    humanName: campaignScript.humanName,
    initialState: campaignScript.initialState,
    reminders: campaignScript.reminders,
    topic: campaignScript.topic ?? undefined,
    audience: campaignScript.audience ?? undefined,
    timing: campaignScript.timing ?? undefined,
    lifeCycle: campaignScript.lifeCycle ?? undefined,
    principle: campaignScript.principle ?? undefined,
    realImpact: campaignScript.realImpact ?? undefined,
    expirationLength: campaignScript.expirationLength,
    updatedAt: campaignScript.updatedAt,
    scriptHistory: campaignScript.scriptHistory,
    createdAt: campaignScript.createdAt,
    createdBy: campaignScript.createdBy,
    isMainstayStaff: campaignScript.isMainstayStaff,
    custom: campaignScript.custom,
    customLogic: campaignScript.customLogic,
    sentToUsers: campaignScript.sentToUsers,
    published: campaignScript.published,
    draft: campaignScript.draft,
    messagingService: campaignScript.messagingService,
    labels: campaignScript.labels ?? [],
    collections: campaignScript.collections ?? [],
  }
}

const initialWorkflowStepResults: IWorkflowSteps = {
  byId: {},
  allIds: [],
  linkBranches: {},
  parentChildTuplesByChildId: {},
  loading: false,
  updating: false,
}

const initialState: ICampaignScriptState = {
  templatePreviewsById: {},
  scriptPreviewsById: {},
  allIds: [],
  allTemplateIds: [],
  loading: false,
  searching: false,
  updating: false,
  creating: false,
  totalCount: 0,
  ui: {
    scriptSearchQuery: '',
    scriptSortOrder: 'desc',
    scriptSortField: 'createdAt',
    fetchingTestContacts: false,
    testContacts: [],
    linking: false,
  },
  steps: initialWorkflowStepResults,
  aggregateSteps: initialWorkflowStepResults,
  introDialogs: {
    list: [],
    loading: false,
    selectedIntroDialog: null,
    reportDownloadProgressById: {},
  },
}

const updateStepState = (
  stepState: IWorkflowSteps,
  campaign:
    | IRecurringCampaignHistoryDetailsResponseData
    | ICampaignHistoryDetailsResponseData
    | (ICampaignTriggerDetail & {
        workflow: ICampaignTriggerDetail['dialog']
      }),
  newSteps?: readonly ICampaignScriptStep[]
): IWorkflowSteps => {
  if (!newSteps) {
    return stepState
  }
  const workflowStepsById: {
    [id: string]: ICampaignScriptStep
  } = _.chain(newSteps || [])
    .keyBy(x => x.id)
    .value()
  const { linkBranches, visitedTuples } = findLinkBranches(
    workflowStepsById,
    campaign.workflow?.initialState
  )

  return {
    ...stepState,
    linkBranches,
    byId: workflowStepsById,
    allIds: Object.keys(visitedTuples),
    parentChildTuplesByChildId: visitedTuples,
    loading: false,
  }
}

const reducer = (
  state: ICampaignScriptState = initialState,
  action: ICampaignScriptActions | ICampaignDetailsActions | IDialogLabelActions
): ICampaignScriptState => {
  switch (action.type) {
    case getType(searchCampaignScripts.success): {
      return {
        ...state,
        totalCount: action.payload.count,
        searching: false,
        scriptPreviewsById: _.chain(action.payload.results)
          .map(mapCampaignScriptSearchData)
          .keyBy((x: ICampaignScriptPreview) => x.id)
          .value(),
        allIds: _.map(action.payload.results, x => x.id),
        ui: {
          ...state.ui,
        },
      }
    }
    case getType(searchScriptTemplates.request): {
      return {
        ...state,
        searching: true,
      }
    }
    case getType(listTemplateScripts.success):
    case getType(searchScriptTemplates.success): {
      return {
        ...state,
        totalCount: action.payload.count,
        searching: false,
        templatePreviewsById: _.chain(action.payload.results)
          .map(mapTemplateScriptSearchData)
          .keyBy(x => x.id)
          .value(),
        allTemplateIds: _.map(action.payload.results, x => x.id),
        ui: {
          ...state.ui,
        },
      }
    }
    case getType(getAllCampaignScripts.success): {
      return {
        ...state,
        totalCount: action.payload.length,
        loading: false,
        scriptPreviewsById: _.chain(action.payload)
          .map(mapCampaignScriptSearchData)
          .keyBy(x => x.id)
          .value(),
        allIds: _.map(action.payload, x => x.id),
      }
    }
    case getType(bulkRemoveDialogLabels.success):
      // Remove requested labels from requested dialogs
      const payloadDialogIds = new Set(action.payload.dialogIds)
      const payloadLabelIds = new Set(action.payload.labelIds)
      const filteredScripts = Object.values(state.scriptPreviewsById)
        .filter(notUndefined)
        .map(p => {
          return {
            ...p,
            labels: p.labels.filter(
              l => !payloadDialogIds.has(p.id) || !payloadLabelIds.has(l.id)
            ),
          }
        })
      return {
        ...state,
        scriptPreviewsById: _.keyBy(filteredScripts, x => x.id),
      }
    case getType(bulkAddDialogLabels.success):
      // Add newly added/created labels to script previews
      const newDialogs = { ...state.scriptPreviewsById }
      const newTemplates = { ...state.templatePreviewsById }

      Object.entries(action.payload.dialogLabelMap).forEach(
        ([dialogId, labels]) => {
          if (newDialogs && newDialogs[dialogId]) {
            const existingDialog = newDialogs[dialogId]
            if (existingDialog) {
              newDialogs[dialogId] = {
                ...existingDialog,
                labels: _.uniqBy(existingDialog.labels.concat(labels), 'id'),
              }
            }
          } else if (newTemplates && newTemplates[dialogId]) {
            const existingTemplate = newTemplates[dialogId]
            if (existingTemplate) {
              newTemplates[dialogId] = {
                ...existingTemplate,
                labels: _.uniqBy(existingTemplate.labels.concat(labels), 'id'),
              }
            }
          } else {
            return
          }
        }
      )
      return {
        ...state,
        scriptPreviewsById: newDialogs,
        templatePreviewsById: newTemplates,
      }
    case getType(deleteDialogLabel.success):
      // Remove requested label from requested dialogs
      const scriptsWithoutLabel = Object.values(state.scriptPreviewsById)
        .filter(notUndefined)
        .map(p => {
          return {
            ...p,
            labels: p.labels.filter(l => l.id !== action.payload.labelId),
          }
        })
      return {
        ...state,
        scriptPreviewsById: _.keyBy(scriptsWithoutLabel, x => x.id),
      }
    case getType(updateDialogLabel.success):
      // Update requested label in dialogs
      const scriptsWithUpdatedLabel = Object.values(state.scriptPreviewsById)
        .filter(notUndefined)
        .map(p => {
          return {
            ...p,
            labels: p.labels.map(l =>
              l.id === action.payload.updatedLabel.id
                ? action.payload.updatedLabel
                : l
            ),
          }
        })
      return {
        ...state,
        scriptPreviewsById: _.keyBy(scriptsWithUpdatedLabel, x => x.id),
      }
    case getType(createCampaignScript.success):
      return {
        ...state,
        templatePreviewsById: {
          ...state.templatePreviewsById,
          [action.payload.dialog.id]: mapTemplateScriptSearchData(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (action.payload.dialog as unknown) as ITemplateScriptPreview
          ),
        },
      }
    case getType(createCampaignScriptInitialState.success):
    case getType(duplicateCampaignScript.success):
    case getType(retrieveCampaignScript.success): {
      const workflowStepsById: IStepsById = _.chain(action.payload.states || [])
        .keyBy(x => x.id)
        .value()
      const { linkBranches, visitedTuples } = findLinkBranches(
        workflowStepsById,
        action.payload.dialog.initialState
      )
      const firstMessageState =
        workflowStepsById &&
        workflowStepsById[action.payload.dialog.initialState]
      if (action.type === getType(createCampaignScriptInitialState.success)) {
        const createdDialogState =
          workflowStepsById[action.payload.states[0].id]
        if (createdDialogState) {
          createdDialogState.editing = true
        }
      }
      const firstMessage =
        firstMessageState?.personalizedPrompt || firstMessageState?.prompt || ''
      const payload = mapCampaignRetrieveToPreview(action.payload.dialog)
      payload['firstMessage'] = firstMessage
      return {
        ...state,
        loading: false,
        creating: false,
        updating: false,
        selectedDialog: mapCampaignScriptRetrieveData(action.payload.dialog),
        steps: {
          ...state.steps,
          loading: false,
          linkBranches,
          parentChildTuplesByChildId: visitedTuples,
          byId: workflowStepsById,
          allIds: Object.keys(visitedTuples),
        },
        scriptPreviewsById: {
          ...state.scriptPreviewsById,
          [action.payload.dialog.id]: {
            ...state.scriptPreviewsById[action.payload.dialog.id],
            ...payload,
          },
        },
        templatePreviewsById: {
          ...state.templatePreviewsById,
          [action.payload.dialog.id]: mapTemplateScriptSearchData(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (payload as unknown) as ITemplateScriptPreview
          ),
        },
      }
    }
    case getType(retrieveCampaignScript.failure): {
      return {
        ...state,
        loading: false,
      }
    }
    case getType(retrieveCampaignScript.request): {
      return {
        ...state,
        loading: true,
      }
    }
    case getType(searchCampaignScripts.failure): {
      return {
        ...state,
        searching: false,
      }
    }
    case getType(searchCampaignScripts.request): {
      return {
        ...state,
        searching: true,
      }
    }
    case getType(updateSortOrder): {
      return {
        ...state,
        ui: {
          ...state.ui,
          scriptSortOrder: action.payload,
        },
      }
    }
    case getType(updateSortfield): {
      return {
        ...state,
        ui: {
          ...state.ui,
          scriptSortField: action.payload,
        },
      }
    }
    case getType(updateSelectedDialogTags): {
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedDialogTags: action.payload,
        },
      }
    }
    case getType(updateScriptSearchQuery): {
      return {
        ...state,
        ui: {
          ...state.ui,
          scriptSearchQuery: action.payload,
        },
      }
    }
    case getType(fetchCampaignDetails.success): {
      const { campaign, workflow_steps } = action.payload
      return {
        ...state,
        steps: updateStepState(state.steps, campaign, workflow_steps),
      }
    }
    case getType(fetchCampaignTriggerDetails.success): {
      const { trigger, workflow_steps } = action.payload
      return {
        ...state,
        aggregateSteps: updateStepState(
          state.aggregateSteps,
          { ...trigger, workflow: trigger.dialog },
          workflow_steps
        ),
        steps: updateStepState(
          state.aggregateSteps,
          { ...trigger, workflow: trigger.dialog },
          workflow_steps
        ),
      }
    }
    case getType(fetchRecurringCampaignDetails.success): {
      const { campaign, workflow_steps } = action.payload
      return {
        ...state,
        steps: updateStepState(state.steps, campaign, workflow_steps),
      }
    }
    case getType(fetchAggRecurringCampaignDetails.success): {
      const { campaign, aggregate_workflow_steps } = action.payload
      return {
        ...state,
        aggregateSteps: updateStepState(
          state.aggregateSteps,
          campaign,
          aggregate_workflow_steps
        ),
      }
    }
    case getType(spliceCampaignScriptState.request):
    case getType(createCampaignScriptInitialState.request): {
      return {
        ...state,
        steps: {
          ...state.steps,
          updating: true,
        },
      }
    }
    case getType(spliceCampaignScriptState.success): {
      // Starting step where we want to modify the transitions
      const stepToChange = _.cloneDeep(
        state.steps.byId[action.payload.dialogStateId]
      )
      if (stepToChange == null) {
        return state
      }

      // Step ID that the transition points to currently
      const oldStep = stepToChange.nextStates[action.payload.transitionType]
        ? _.cloneDeep(stepToChange.nextStates[action.payload.transitionType])
        : { default: false }
      // The newly created state
      const newStep = _.cloneDeep(action.payload.newDialogStateData)
      // Hook up old step to new step
      newStep.nextStates[action.payload.spliceTransition || 'default'] = oldStep
      newStep.editing = true
      stepToChange.nextStates[action.payload.transitionType] = {
        default: newStep.id,
      }
      // Here we check the collection of link nodes and see if we need to
      // do an update there as well.
      const maybeLinkBranch =
        state.steps.linkBranches[action.payload.dialogStateId]

      const linkBranches =
        maybeLinkBranch != null &&
        maybeLinkBranch[action.payload.transitionType]
          ? {
              ...state.steps.linkBranches,
              [action.payload.dialogStateId]: {
                ...maybeLinkBranch,
                [action.payload.transitionType]: false,
              },
              [newStep.id]: {
                [action.payload.spliceTransition || 'default']: true,
              },
            }
          : state.steps.linkBranches
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          byId: {
            ...state.steps.byId,
            [newStep.id]: newStep,
            [stepToChange.id]: stepToChange,
          },
          allIds: _.uniq([...state.steps.allIds, newStep.id]),
          linkBranches,
          updating: false,
        },
      }
    }
    case getType(spliceCampaignScriptState.failure):
    case getType(createCampaignScriptInitialState.failure): {
      return {
        ...state,
        steps: {
          ...state.steps,
          updating: false,
        },
      }
    }
    case getType(destroyCampaignScript.success): {
      return {
        ...state,
        allIds: [...state.allIds.filter(x => x !== action.payload)],
      }
    }
    case getType(updateCampaignScript.request): {
      return {
        ...state,
        updating: true,
      }
    }
    case getType(updateCampaignScript.success): {
      const existingScriptPreview = state.scriptPreviewsById[action.payload.id]
      const updatedScriptPreviewsById = {
        ...state.scriptPreviewsById,
        [action.payload.id]: existingScriptPreview
          ? {
              ...existingScriptPreview,
              published: action.payload.published,
            }
          : undefined,
      }
      return {
        ...state,
        updating: false,
        scriptPreviewsById: updatedScriptPreviewsById,
        allIds: _.uniq([...state.allIds, action.payload.id]),
        selectedDialog: {
          ...state.selectedDialog,
          ...mapCampaignScriptRetrieveData(action.payload),
        },
        steps: action.payload.initialState
          ? state.steps
          : { ...initialState.steps },
        templatePreviewsById: {
          ...state.templatePreviewsById,
          [action.payload.id]: mapTemplateScriptSearchData(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (action.payload as unknown) as ITemplateScriptPreview
          ),
        },
      }
    }
    case getType(updateCampaignScript.failure): {
      return {
        ...state,
        updating: false,
      }
    }
    case getType(updateCampaignScriptState.request): {
      return {
        ...state,
        steps: {
          ...state.steps,
          updating: true,
        },
      }
    }
    case getType(updateCampaignScriptState.success): {
      return {
        ...state,
        steps: {
          ...state.steps,
          byId: {
            ...state.steps.byId,
            [action.payload.id]: {
              ...action.payload,
            },
          },
          updating: false,
        },
      }
    }
    case getType(campaignScriptDragState): {
      const dialogStateToUpdate = state.steps.byId[action.payload.dialogStateId]
      if (!dialogStateToUpdate) {
        return { ...state }
      }
      return {
        ...state,
        steps: {
          ...state.steps,
          byId: {
            ...state.steps.byId,
            [action.payload.dialogStateId]: {
              ...dialogStateToUpdate,
              multipleChoices: action.payload.multipleChoices,
              nextStates: action.payload.nextStates,
            },
          },
        },
      }
    }
    case getType(editScriptNodeAction): {
      const dialogStateToUpdate = state.steps.byId[action.payload.dialogStateId]
      if (!dialogStateToUpdate) {
        return { ...state }
      }
      return {
        ...state,
        steps: {
          ...state.steps,
          byId: {
            ...state.steps.byId,
            [action.payload.dialogStateId]: {
              ...dialogStateToUpdate,
              editing: action.payload.editing,
            },
          },
        },
      }
    }
    case getType(updateCampaignScriptState.failure): {
      return {
        ...state,
        updating: false,
      }
    }
    case getType(setShowPauseTimeForm): {
      const dialogStateToUpdate = state.steps.byId[action.payload.dialogStateId]
      if (!dialogStateToUpdate) {
        return { ...state }
      }
      return {
        ...state,
        steps: {
          ...state.steps,
          byId: {
            ...state.steps.byId,
            [action.payload.dialogStateId]: {
              ...dialogStateToUpdate,
              showPauseTimeForm: action.payload.showForm,
            },
          },
          updating: false,
        },
      }
    }
    case getType(addBlankExitAction): {
      const dialogStateToUpdate = state.steps.byId[action.payload.dialogStateId]
      if (!dialogStateToUpdate) {
        return { ...state }
      }
      const updated = {
        ...dialogStateToUpdate,
        exitActions: [
          ...(dialogStateToUpdate.exitActions || []),
          action.payload.action,
        ],
      }

      return {
        ...state,
        steps: {
          ...state.steps,
          byId: {
            ...state.steps.byId,
            [action.payload.dialogStateId]: updated,
          },
        },
      }
    }
    case getType(clearBlankExitAction): {
      const dialogStateToUpdate = state.steps.byId[action.payload.dialogStateId]
      if (!dialogStateToUpdate) {
        return { ...state }
      }
      const updated = {
        ...dialogStateToUpdate,
        exitActions: [
          ...(dialogStateToUpdate.exitActions || []).filter(
            a => !a.newlyCreated
          ),
        ],
      }

      return {
        ...state,
        steps: {
          ...state.steps,
          byId: {
            ...state.steps.byId,
            [action.payload.dialogStateId]: updated,
          },
        },
      }
    }
    case getType(deleteCampaignScriptStateEdge.request): {
      return {
        ...state,
        steps: {
          ...state.steps,
          updating: true,
        },
      }
    }
    case getType(deleteCampaignScriptStateEdge.success): {
      if (!action.payload.dialogStateId) {
        return state
      }
      const startState = _.cloneDeep(
        state.steps.byId[action.payload.dialogStateId]
      )
      if (startState == null) {
        return state
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const toDeleteId = startState.nextStates[action.payload.transitionType]!
        .default
      if (typeof toDeleteId !== 'string') {
        throw Error('invalid delete target')
      }
      const toDelete = _.cloneDeep(state.steps.byId[toDeleteId])
      if (toDelete == null) {
        return state
      }

      // here we find any remaining references to the deleted node and clean them up if necessary
      const byId = _.mapValues(state.steps.byId, x => {
        if (x && x.nextStates) {
          // if the next states dictionary doesnt contain the deleted
          // state id, do nothing
          if (!_.find(x.nextStates, y => !!y && y.default === toDeleteId)) {
            return x
          }
          // Deleting a link is a special case since we want to avoid splicing the child state
          // to the parent and also avoid cleaning up any other references
          if (action.payload.isLink) {
            return {
              ...x,
              nextStates: {
                ...x.nextStates,
                [action.payload.transitionType]: { default: false },
              },
            }
          }
          // map over the states replacing each matching transition with false
          const states = _.mapValues(x.nextStates, (y, key) => {
            if (
              action.payload.dialogStateId === x.id &&
              // tslint:disable-next-line: triple-equals
              action.payload.transitionType == key &&
              (toDelete.promptType === PromptType.auto ||
                toDelete.promptType === PromptType.open) &&
              !action.payload.isLink
            ) {
              return _.cloneDeep(toDelete.nextStates.default)
            }
            if (!!y && y?.default === toDeleteId) {
              return {
                ...y,
                default: false,
              }
            }
            return y
          })
          return {
            ...x,
            nextStates: states,
          }
        }
        return x
      })
      const linkBranches = _.mapValues(
        state.steps.linkBranches,
        (value, key) => {
          const state = byId[key]
          if (
            action.payload.isLink &&
            state?.id === action.payload.dialogStateId &&
            // tslint:disable-next-line: triple-equals
            key == action.payload.transitionType
          ) {
            return {
              ...value,
              [key]: false,
            }
          }
          return _.mapValues(value, (isLink, transition) => {
            if (!state || !state.nextStates?.[transition]?.default) {
              return false
            }
            return isLink
          })
        }
      )
      return {
        ...state,
        steps: {
          ...state.steps,
          byId,
          linkBranches,
          updating: false,
        },
      }
    }
    case getType(createCampaignScript.request):
    case getType(duplicateCampaignScript.request): {
      return {
        ...state,
        creating: true,
      }
    }
    case getType(createCampaignScript.failure):
    case getType(duplicateCampaignScript.failure): {
      return {
        ...state,
        creating: false,
      }
    }
    case getType(fetchTestContacts.request): {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchingTestContacts: true,
        },
      }
    }
    case getType(fetchTestContacts.success): {
      return {
        ...state,
        ui: {
          ...state.ui,
          testContacts: action.payload,
          fetchingTestContacts: false,
        },
      }
    }
    case getType(fetchTestContacts.failure): {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchingTestContacts: false,
        },
      }
    }
    case getType(startLinking): {
      return {
        ...state,
        ui: {
          ...state.ui,
          linking: true,
          linkFromId: action.payload.linkStepId,
          linkTransition: action.payload.linkTransitionKey,
        },
      }
    }
    case getType(stopLinking): {
      return {
        ...state,
        ui: {
          ...state.ui,
          linking: false,
          linkFromId: undefined,
          linkTransition: undefined,
        },
      }
    }
    case getType(createLink.success): {
      // Here we're adding to the collection of link branches, setting defaults if necessary.
      const linkBranch =
        { ...state.steps.linkBranches[action.payload.dialogStateId] } || {}
      linkBranch[action.payload.linkTransitionKey] = true
      return {
        ...state,
        steps: {
          ...state.steps,
          byId: {
            ...state.steps.byId,
            [action.payload.dialogStateId]: {
              ...state.steps.byId[action.payload.dialogStateId],
              ...action.payload.data,
            },
          },
          linkBranches: {
            ...state.steps.linkBranches,
            [action.payload.dialogStateId]: linkBranch,
          },
        },
        updating: false,
      }
    }
    case getType(createLink.failure): {
      return {
        ...state,
        steps: {
          ...state.steps,
          updating: false,
        },
      }
    }
    case getType(createLink.request): {
      return {
        ...state,
        steps: {
          ...state.steps,
          updating: true,
        },
      }
    }
    case getType(fetchIntroDialogsList.request): {
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          loading: true,
        },
      }
    }
    case getType(fetchIntroDialogsList.success): {
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          loading: false,
          list: action.payload,
        },
      }
    }
    case getType(fetchIntroDialogsList.failure): {
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          loading: false,
        },
      }
    }
    case getType(fetchIntroDialogDetails.request): {
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          loading: true,
        },
      }
    }
    case getType(fetchIntroDialogDetails.success): {
      const workflowStepsById: IStepsById = _.chain(action.payload.states || [])
        .keyBy(x => x.id)
        .value()
      const { linkBranches, visitedTuples } = findLinkBranches(
        workflowStepsById,
        action.payload.dialog.initialState
      )
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          loading: false,
          selectedIntroDialog: action.payload.dialog,
        },
        steps: {
          ...state.steps,
          linkBranches,
          parentChildTuplesByChildId: visitedTuples,
          byId: workflowStepsById,
          allIds: Object.keys(visitedTuples),
        },
      }
    }
    case getType(fetchIntroDialogDetails.failure): {
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          loading: false,
        },
      }
    }
    case getType(generateDialogReport.request): {
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          reportDownloadProgressById: {
            ...state.introDialogs.reportDownloadProgressById,
            [action.payload.dialogId]: 0,
          },
        },
      }
    }
    case getType(generateDialogReportProgress): {
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          reportDownloadProgressById: {
            ...state.introDialogs.reportDownloadProgressById,
            [action.payload.dialogId]: action.payload.progress,
          },
        },
      }
    }
    case getType(generateDialogReport.success):
    case getType(generateDialogReport.failure):
      return {
        ...state,
        introDialogs: {
          ...state.introDialogs,
          reportDownloadProgressById: {
            ...state.introDialogs.reportDownloadProgressById,
            [action.payload.dialogId]: undefined,
          },
        },
      }
    case getType(updateTemplateCollections.success): {
      const newTemplatePreviewsById: { [k: string]: ITemplateScript } = {}
      Object.keys(state.templatePreviewsById).forEach((dialogId: string) => {
        const currentDialog: ITemplateScript | undefined =
          state.templatePreviewsById[dialogId]
        if (!currentDialog) {
          return state
        }
        if (!action.payload.templateIds.includes(dialogId)) {
          if (currentDialog !== undefined) {
            newTemplatePreviewsById[dialogId] = {
              ...currentDialog,
            }
          }
        } else {
          const collectionsToAdd = action.payload.collectionsToAdd.filter(
            (a: ITemplateCollection) =>
              !currentDialog?.collections?.map(c => c.id).includes(a.id)
          )
          const updatedTemplate: ITemplateScript = {
            ...currentDialog,
            collections: (currentDialog?.collections || [])
              .filter(
                c =>
                  !action.payload.collectionsToRemove
                    .map(r => r.id)
                    .includes(c.id)
              )
              .concat(collectionsToAdd),
          }
          if (updatedTemplate !== undefined) {
            newTemplatePreviewsById[dialogId] = updatedTemplate
          }
        }
      })
      return {
        ...state,
        templatePreviewsById: newTemplatePreviewsById,
      }
    }
    case getType(refreshDialogUpdatedAt):
      return {
        ...state,
        selectedDialog: !!state.selectedDialog
          ? { ...state.selectedDialog, updatedAt: new Date().toLocaleString() }
          : undefined,
      }

    default:
      return state
  }
}

export default reducer
