import React, { useState } from 'react'
import { ITextInputProps, TextInput } from 'components/TextInput/TextInput'
import classnames from 'classnames'
import { TextArea, ITextAreaProps } from 'components/TextArea/TextArea'

export interface ILabeledInputProps {
  label: string
  id?: string
  value?: string
  children?: React.ReactNode
  inputClassName?: string
  labelClassName?: string
}

export const LabeledTextInput = ({
  children,
  className,
  inputClassName,
  labelClassName,
  ...props
}: Omit<ITextInputProps, 'placeholder'> & ILabeledInputProps) => {
  const [active, setActive] = useState(false)
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setActive(false)
    if (props.onBlur) {
      props.onBlur(e)
    }
  }
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setActive(true)
    if (props.onFocus) {
      props.onFocus(e)
    }
  }
  const rootClassName = classnames(
    'form-group',
    'form-group-labeled',
    'text-input-labeled',
    props.height ? `form-group-${props.height}` : 'form-group-md',
    className,
    props.value && 'has-value',
    active ? 'active-label-input' : 'inactive-label-input'
  )
  return (
    <div className={rootClassName} style={{ height: '50px' }}>
      <label
        className={classnames(labelClassName, {
          'input-label--error': props?.error,
        })}
        htmlFor={props.id}>
        {props.label}
      </label>
      <TextInput
        {...props}
        style={{ height: '50px' }}
        required={props.required}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={props.value}
        id={props.id}
        className={inputClassName}
      />
      {children}
    </div>
  )
}

export const LabeledTextArea = ({
  children,
  ...props
}: Omit<ITextAreaProps, 'placeholder'> & {
  readonly textAreaClassName?: string
  readonly label: string
  readonly children?: React.ReactNode
}) => {
  const [active, setActive] = useState(false)
  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setActive(false)
    if (props.onBlur) {
      props.onBlur(e)
    }
  }
  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setActive(true)
    if (props.onFocus) {
      props.onFocus(e)
    }
  }
  const className = classnames(
    'form-group',
    'form-group-labeled',
    'text-input-labeled',
    props.className,
    props.value && 'has-value',
    active ? 'active-label-input' : 'inactive-label-input'
  )
  const { ref, ...rest } = props
  return (
    <div className={className}>
      <label htmlFor={props.id}>{props.label}</label>
      <TextArea
        {...rest}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={classnames(
          { 'mb-3': !props.overrideTextAreaMargin },
          props.textAreaClassName
        )}
      />
      {children}
    </div>
  )
}
