import _ from 'lodash'
import { getType } from 'typesafe-actions'
import { switchCurrentInstitutionSuccess } from 'store/triage/profile/actions'
import * as ACTION_TYPES from 'store/triage/profile/actionTypes'

import {
  IContactsActions,
  fetchContact,
  updateContact,
  addNoteToContact,
  deleteContactNote,
  editContactNote,
  updateAttributeValues,
  getContactCounts,
  archiveOrUnarchiveContacts,
  updateArchivePolling,
  clearCurrentContact,
} from 'store/contacts/actions'
import { ICampaignStatus } from 'store/triage/chat/contactsReducer'
import { IContactAttributeValues } from 'store/personalization/contactAttributes/selectors'
import { TransportId } from 'store/transport'
import { IMessagingStatusOption } from 'components/ContactPanel/EditableContactPanel'

export type IContactListOrdering = '-createdAt' | 'createdAt'

export enum AuthStatus {
  none = 'NONE',
  authenticated = 'SUCCESS',
  authfailed = 'FAILED',
  expired = 'SUCCESS_EXPIRED',
}
export interface IContactNote {
  id: string
  createdBy: string
  createdByName: string
  createdAt: Date
  updatedAt: Date
  text: string
  hidden?: boolean
}

export interface IContactLabel {
  id: string
  text: string
  institutionId: string
  createdAt: string
  createdBy: string
  count: null | number
}

export interface IContactIdToLabel {
  [key: string]: string
}

export interface IContact {
  id: string
  name: {
    first: string
    last: string
  }
  phone: string
  email: string
  importSegmentLabels: string[]
  contactLabels: IContactLabel[]
  active: boolean
  transport?: TransportId
  countryCallingCode: string
  created: string
}

export interface IFullContact {
  id: string
  name?: {
    first?: string
    last?: string
    preferred?: string
  }
  crmId?: string
  enrollmentId?: string
  phone: string
  countryCallingCode?: string
  previousPhone?: string
  email?: string
  importSegmentLabels: string[]
  importIdsToLabel?: IContactIdToLabel
  contactLabels: IContactLabel[]
  studentType?: string
  transport?: TransportId
  transports?: TransportId[]
  language: string
  readonly created: Date
  _contactSettings?: {
    canText: boolean
    permanentlySoftStopped?: boolean
    contacted?: boolean
    finished?: boolean
    generalOptIn?: boolean
    needsReintroduction?: boolean
    nonWorkingNumber?: boolean
    wrongNumber?: boolean
    permittedUser?: boolean
    permittedUserMutable?: boolean
  }
  deliveryFailureCount: number
  notes: IContactNote[]
  _testUser: boolean
  internal: boolean
  aiDisabled: boolean
  knownUser: boolean
  campaignHistory: {
    [key: string]: ICampaignStatus
  }
  _dialog?: {
    id: string
  }
  application?: {
    status: string
  }
  webBot?: {
    webBotEnabled?: boolean
  }
  attributeValues?: IContactAttributeValues
  authentication: {
    validatedStatus: AuthStatus
    expires?: string
    authedContactId?: string
    authedContactName?: string
  }
  previouslyOptedOutOfSMS: boolean
  messagingStatus: Record<string, IMessagingStatusOption['value']>
}

export interface IContactCounts {
  total: number
  activeNotTest: number
  activeTest: number
  archived: number
}

export enum IArchiveActionType {
  ARCHIVE = 'ARCHIVE',
  UNARCHIVE = 'UNARCHIVE',
}

interface IContactListState {
  isLoading: boolean
  currentEditContact: IFullContact | undefined
  currentEditContactLoading: boolean
  loadingContactAttributeValues: boolean
  loadingContactCounts: boolean
  archiveActionCompleted: boolean
  contactCounts: {
    activeNotTest: number
    activeTest: number
    archived: number
    total: number
  }
  errorFetchingContactCounts: boolean
  archiveProgress: number
  haveFiltersChanged: boolean
  ui: {
    creatingNote: boolean
    getErrorCreatingNote: boolean
  }
}

const initialState: IContactListState = {
  isLoading: true,
  currentEditContact: undefined,
  currentEditContactLoading: false,
  loadingContactAttributeValues: false,
  loadingContactCounts: false,
  archiveActionCompleted: false,
  contactCounts: {
    activeNotTest: 0,
    activeTest: 0,
    archived: 0,
    total: 0,
  },
  errorFetchingContactCounts: false,
  archiveProgress: 0,
  haveFiltersChanged: false,
  ui: {
    creatingNote: false,
    getErrorCreatingNote: false,
  },
}

export const INTL_PHONE_REGEX = /^\d{1,14}$|UNSET/
export const DOMESTIC_PHONE_REGEX = /^\d{10}$|UNSET/
export const COUNTRY_CALLING_CODE_REGEX = /^[1-9]\d{0,3}$/

const reducer = (
  state: IContactListState = initialState,
  action: IContactsActions | ReturnType<typeof switchCurrentInstitutionSuccess>
): IContactListState => {
  switch (action.type) {
    case getType(fetchContact.request):
      return {
        ...state,
        currentEditContactLoading: true,
      }
    case getType(fetchContact.success):
      return {
        ...state,
        currentEditContact: action.payload.contact,
        currentEditContactLoading: false,
      }
    case getType(updateContact.success):
      return {
        ...state,
        currentEditContact: {
          ...action.payload.contact,
          attributeValues: state.currentEditContact?.attributeValues,
        },
        currentEditContactLoading: false,
      }
    case getType(fetchContact.failure):
    case getType(updateContact.failure):
      return {
        ...state,
        isLoading: false,
        currentEditContactLoading: false,
      }
    case ACTION_TYPES.SWITCH_CURRENT_INSTITUTION_SUCCESS:
      return initialState
    case getType(addNoteToContact.request):
      return {
        ...state,
        ui: {
          ...state.ui,
          creatingNote: true,
        },
      }
    case getType(addNoteToContact.success):
    case getType(editContactNote.success):
    case getType(deleteContactNote.success):
      return {
        ...state,
        ui: {
          ...state.ui,
          creatingNote: false,
          getErrorCreatingNote: false,
        },
        currentEditContact: state.currentEditContact
          ? {
              ...state.currentEditContact,
              notes: action.payload.notes,
            }
          : undefined,
      }
    case getType(addNoteToContact.failure):
      return {
        ...state,
        ui: {
          ...state.ui,
          getErrorCreatingNote: true,
          creatingNote: false,
        },
      }
    case getType(updateAttributeValues.request):
      return {
        ...state,
        loadingContactAttributeValues: true,
      }
    case getType(updateAttributeValues.success):
      return {
        ...state,
        loadingContactAttributeValues: false,
        currentEditContact: state.currentEditContact
          ? {
              ...state.currentEditContact,
              attributeValues: action.payload.attributeValues,
            }
          : undefined,
      }
    case getType(updateAttributeValues.failure):
      return {
        ...state,
        loadingContactAttributeValues: false,
      }
    case getType(getContactCounts.request):
      return {
        ...state,
        loadingContactCounts: true,
        errorFetchingContactCounts: false,
      }
    case getType(getContactCounts.success):
      return {
        ...state,
        contactCounts: action.payload.data,
        loadingContactCounts: false,
        errorFetchingContactCounts: false,
      }
    case getType(getContactCounts.failure):
      return {
        ...state,
        loadingContactCounts: false,
        errorFetchingContactCounts: true,
      }
    case getType(archiveOrUnarchiveContacts.request):
      return {
        ...state,
        archiveActionCompleted: false,
      }
    case getType(archiveOrUnarchiveContacts.success):
      return {
        ...state,
        archiveActionCompleted: true,
        contactCounts: action.payload.data,
      }
    case getType(archiveOrUnarchiveContacts.failure):
      return {
        ...state,
        archiveActionCompleted: true,
      }
    case getType(updateArchivePolling.request):
      return {
        ...state,
        archiveProgress: action.payload.progress,
      }
    case getType(updateArchivePolling.success):
      return {
        ...state,
        archiveProgress: 0,
      }
    case getType(updateArchivePolling.failure):
      return {
        ...state,
        archiveProgress: 0,
      }
    case getType(clearCurrentContact):
      return {
        ...state,
        currentEditContact: undefined,
      }
    default:
      return state
  }
}

export default reducer
