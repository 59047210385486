import React, { useState, useEffect, useReducer } from 'react'
import {
  contactNotesReducer,
  cancelEdit,
  setEditMode,
  closeMenu,
  setNoteText,
} from 'components/ContactPanel/contactNotesReducer'
import moment from 'moment'
import { useSelector, useDispatch } from 'util/hooks'
import pluralize from 'pluralize'
import {
  getActiveContactNotes,
  getActiveContactId,
  getCreatingNote,
  getErrorCreatingNote,
} from 'store/contacts/selectors'
import {
  addNoteToContactAsync,
  editContactNoteAsync,
  deleteContactNoteAsync,
} from 'store/contacts/thunks'
import { TextArea } from 'components/TextArea/TextArea'
import { Button } from 'components/Button/Button'
import { getUserId, getUserName } from 'store/triage/profile/selectors'
import { IContactNote } from 'store/contacts/reducer'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { ActionMenu } from 'components/ActionMenu/ActionMenu'

import 'components/ContactPanel/ContactPanel.scss'

interface IContactNoteProps {
  note: IContactNote
  newNote?: boolean
  onDelete: (noteId: string) => void
  onCancel?: () => void
  onSave: (note: IContactNote, noteId: string, newNote: boolean) => void
  showMenuButton?: boolean
}
const ContactNote = ({
  note,
  onCancel,
  newNote = false,
  onDelete,
  onSave,
  showMenuButton,
}: IContactNoteProps) => {
  const contactNotesReducerInitialState = {
    menuOpen: false,
    editMode: newNote,
    noteText: note.text,
  }
  const [state, dispatch] = useReducer(
    contactNotesReducer,
    contactNotesReducerInitialState
  )
  const enableMenu = !newNote
  const handleDelete = () => {
    dispatch(closeMenu())
    onDelete(note.id)
  }
  const handleEdit = () => {
    dispatch(setEditMode())
  }
  const inputValid = state.noteText.trim().length > 0
  const handleNoteTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setNoteText(e.target.value))
  }
  const handleSave = () => {
    if (inputValid) {
      onSave({ ...note, text: state.noteText }, note.id, Boolean(newNote))
      dispatch(cancelEdit())
    }
  }
  const handleCancel = () => {
    dispatch(cancelEdit())
    dispatch(setNoteText(note.text))
    if (onCancel) {
      onCancel()
    }
  }

  const moveCaretToEnd = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const el = event.target
    el.focus()
    el.setSelectionRange(el.value.length, el.value.length)
  }

  return (
    <div className="border mb-3 p-3">
      <div className="text-muted text-blue-grey-300 mb-1 d-flex justify-content-between">
        <span>
          {note.createdByName},{' '}
          {moment(note.createdAt).format('M/D/YY,  h:mma')}
        </span>
        {(showMenuButton || newNote) && !state.editMode && (
          <ActionMenu
            popoverPlacement="bottom-end"
            className="mt-2"
            disabled={!enableMenu}
            menuItems={[
              {
                label: 'Edit Note',
                icon: <AHIcon name="edit" />,
                onSelect: handleEdit,
              },
              {
                label: 'Delete Note',
                icon: <AHIcon name="delete" />,
                onSelect: handleDelete,
              },
            ]}
          />
        )}
      </div>
      {state.editMode ? (
        <div>
          <TextArea
            className="border"
            autoFocus
            onFocus={moveCaretToEnd}
            value={state.noteText}
            onChange={handleNoteTextChange}
          />
          <div className="my-2">
            <Button
              className="mr-2"
              color="primary"
              onClick={handleSave}
              disabled={!inputValid}>
              Save
            </Button>
            <Button color="primary" outlined onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-wrap">{state.noteText}</div>
      )}
    </div>
  )
}

const ContactNotes = () => {
  const creatingNote = useSelector(getCreatingNote)
  const errorCreatingNote = useSelector(getErrorCreatingNote)
  const notes = useSelector(getActiveContactNotes)
  const currentUserId = useSelector(getUserId)
  const currentUserName = useSelector(getUserName)
  const currentContactId = useSelector(getActiveContactId)

  const dispatch = useDispatch()
  const handleAddNote = (note: IContactNote) => {
    if (currentContactId) {
      addNoteToContactAsync(dispatch)(currentContactId, note)
      setInputtingNote(false)
    }
  }
  const handleEditNote = (note: IContactNote, noteId: string) => {
    if (currentContactId) {
      editContactNoteAsync(dispatch)(currentContactId, noteId, note.text)
    }
  }
  const handleDeleteNote = (noteId: string) => {
    if (currentContactId) {
      deleteContactNoteAsync(dispatch)(currentContactId, noteId)
    }
  }

  const [expanded, setExpanded] = useState(notes.length < 7)
  const [inputtingNote, setInputtingNote] = useState(false)
  const handleExpand = () => {
    setExpanded(prev => notes.length > 0 && !prev)
  }
  const handleStartNewNote = () => {
    setInputtingNote(true)
    setExpanded(true)
  }
  const handleCancelAddNote = () => setInputtingNote(false)

  const blankNote = (): IContactNote => ({
    id: 'new',
    text: '',
    createdBy: currentUserId,
    createdByName: currentUserName,
    createdAt: new Date(),
    updatedAt: new Date(),
  })

  useEffect(() => {
    if (errorCreatingNote) {
      setInputtingNote(false)
    }
  }, [errorCreatingNote])

  useEffect(() => {
    if (notes.length === 0) {
      setExpanded(false)
    }
  }, [notes])

  return (
    <>
      <h5
        className="text-primary fw-500 d-flex align-items-center pointer"
        onClick={handleStartNewNote}>
        <span className="align-add-icon">
          <AHIcon className="mr-1 pt-1" name="add_circle_outline" />
        </span>
        <p className="m-0">Add Note</p>
      </h5>
      <div
        className="pointer my-2 d-flex align-items-center"
        onClick={handleExpand}>
        <AHIcon
          overrideWidth
          name={expanded ? 'expand_more' : 'navigate_next'}
        />
        <span className="ml-1">{pluralize('Note', notes.length, true)}</span>
      </div>
      {expanded && (
        <div>
          {(inputtingNote || creatingNote) && (
            <ContactNote
              newNote
              note={blankNote()}
              onCancel={handleCancelAddNote}
              onDelete={noteId => handleDeleteNote(noteId)}
              onSave={handleAddNote}
            />
          )}
          {notes.map((n: IContactNote) => (
            <ContactNote
              key={n.id}
              note={n}
              onDelete={noteId => handleDeleteNote(noteId)}
              onSave={handleEditNote}
              showMenuButton={n.createdBy === currentUserId}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default ContactNotes
